PageApp.Views.TimedItemLayout = Backbone.Marionette.LayoutView.extend({
  autobidFieldContents: '',
  defaultImageDimensionsByLayout: {
    grid: {
      height: 350,
      width: 480
    },
    list: {
      height: 350,
      width: 480
    },
    lightBox: {}
  },
  templateGrid: hbs.templates.timedItemGridLayout,
  templateList: hbs.templates.timedItemListLayout,
  getTemplate: function () {
    var layout = myApp.ent.user.getPreference('layoutForBidding')
    if (layout === 'grid') {
      return this.templateGrid
    } else {
      return this.templateList
    }
  },
  getDefaultImageDimensions: function (layout) {
    var dimensions = this.defaultImageDimensionsByLayout[layout] || false
    if (dimensions) {
      dimensions.operation = myApp.ent.config.get('auctionImageTransformOperation')
    }
    return dimensions
  },
  initialize: function (options) {
    this.options = options
  },
  modelEvents: {
    'change': 'render'
  },
  className: function () {
    var layout = myApp.ent.user.getPreference('layoutForBidding', { value: 'grid' })
    var layoutClass = (layout === 'grid') ? 'grid-item grid-container--item thumbnail timedItem' : 'timedItem col-xs-12'
    var filter = myApp.ent.status.getMyBidsCurrentFilter()
    var userStatus = this.model.get('userStatus') || myApp.ent.timedHelper.getUserStatus(this.model.attributes)
    if (filter) {
      var hidden = false
      switch (filter.name) {
        case 'WINNING':
          hidden = (!myApp.ent.timedHelper.isWinning(this.model.attributes))
          break
        case 'LOSING':
          hidden = (!myApp.ent.timedHelper.isLosing(userStatus.sellingStatus))
          break
        case 'WON':
          hidden = (!myApp.ent.timedHelper.isWon(this.model.attributes))
          break
        case 'LOST':
          hidden = (!myApp.ent.timedHelper.isLost(userStatus.sellingStatus))
          break
        case 'PENDING':
          hidden = (!myApp.ent.purchaseEntity.getOfferByStatus(this.model.attributes, 'pending'))
          break
        case 'REJECTED':
          hidden = (!myApp.ent.purchaseEntity.getOfferByStatus(this.model.attributes, 'rejected'))
          break
        case 'PURCHASED':
          hidden = (!myApp.ent.purchaseEntity.getOfferByStatus(this.model.attributes, 'accepted'))
      }
      var filterClass = (hidden) ? ' hidden' : ''
    }
    return layoutClass + filterClass
  },
  events: {
    'click .x-similar': 'handleSimilar',
    'click .x-confirm-bid': 'handlePlaceBid',
    'click .x-auto-bid': 'handleAutoBid',
    'keyup .x-auto-bid-input': 'handleAutoBidKeyup'
  },
  serializeData: function () {
    this.model.set({ 'userStatus': myApp.ent.timedHelper.getUserStatus(this.model.attributes) }, { silent: true })
    var clientOptions = this.model.get('clientOptions')
    var itemType = this.model.get('itemType')
    var timedBiddingInfo = this.model.get('timedBiddingInfo')
    var userStatus = this.model.get('userStatus')

    var isBoughtWithBuyNow = userStatus.sellingStatus === 15 // SellingStatus 15 = buynow
    var isCatalogue = itemType.catalogue
    var isClosed = this.model.get('manualClose') || myApp.ent.timedHelper.isClosed(this.model.attributes)
    var isWon = myApp.ent.timedHelper.isWon(this.model.attributes)
    var hasTimeLeft = !isCatalogue && timedBiddingInfo.showBidStatusEndDate
    var hasBidCount = (clientOptions.showNumberOfBids && (isClosed || itemType.supportsBidding))
    var hasBidIncrements = (!isClosed && clientOptions.showBidIncrements && itemType.supportsBidding)
    var hasWinningBidAmount = (itemType.supportsBidding && (!isClosed || clientOptions.showWinningBidAmount || isWon))
    var hasDisplayPrice = (!itemType.supportsBidding && timedBiddingInfo.displayPrice > 0)
    var hasNumberOfUnits = (!isClosed && (timedBiddingInfo.numberOfUnits > 1 || timedBiddingInfo.tenderEnabled || timedBiddingInfo.tenderPctEnabled))

    var remainingUnits = myApp.reqres.request('i16:getString', 'ItemCodes_ITEM_UNITS_AVAILABLE_MESSAGE_BULKBUY').replace('{0}', timedBiddingInfo.numberOfUnitsRemaining)
    var totalUnits = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_NUMBER_OF_UNITS') + ' ' + timedBiddingInfo.numberOfUnits
    var bidTypeMessage = myApp.ent.timedHelper.getBidTypeMessage(this.model.attributes)
    var externalPageUrl = this.model.get('fullExternalWebpageUrl')

    return {
      bidding: {
        count: myApp.ent.timedHelper.getNumberOfBids(this.model.attributes),
        hasBidding: itemType.supportsBidding,
        hasCount: hasBidCount,
        hasIncrements: hasBidIncrements,
        hasReserveIndicator: userStatus.showReserveIndicator,
        hasWinningBidAmount: hasWinningBidAmount,
        highBidAmountCalculation: myApp.ent.timedHelper.getHighBidAmountCalculation(this.model.attributes),
        incrementWithCurrency: myApp.ent.timedHelper.getBidIncrementWithCurrency(this.model.attributes),
        percentOfReserve: userStatus.percentOfReserve
      },
      contextPath: myApp.ent.config.get('contextPath'),
      displayPrice: (isClosed && isBoughtWithBuyNow)
        ? myApp.ent.timedHelper.getMinimumOfferOrBuyNowPriceWithCurrency(this.model.attributes)
        : myApp.ent.timedHelper.getHighBidMessage(this.model.attributes),
      displayPriceWithCurrency: myApp.ent.timedHelper.getDisplayPriceWithCurrency(this.model.attributes),
      externalWebpageUrl: (externalPageUrl && externalPageUrl.length > 0) ? externalPageUrl : false,
      hasDisplayPrice: hasDisplayPrice,
      hasFlags: this.model.get('showFlags'),
      hasItemRoundels: this.model.get('uiShowItemRoundels'),
      hasNumberOfUnits: hasNumberOfUnits,
      imageDimensions: this.getDefaultImageDimensions(myApp.ent.user.getPreference('layoutForBidding')),
      isArchived: this.model.get('archived'),
      isBoughtWithBuyNow: isBoughtWithBuyNow,
      isClosed: isClosed,
      isGallery: (myApp.ent.config.get('page') !== 'itemland'),
      isOvertimeBidding: this.model.attributes.timedBiddingInfo.overtimeBidding,
      isVisible: this.model.get('timedBiddingInfo').publicVisible,
      lightboxImageDimensions: this.getDefaultImageDimensions('lightbox'),
      messages: {
        awaitingImage: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AWAITING_IMAGE'),
        bidCount: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SALES_NUM_BIDS'),
        bidIncrement: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BID_INCREMENT'),
        bidMessage: (!isClosed)
          ? myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_HIGHEST_BID')
          : (isBoughtWithBuyNow)
            ? myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_BUY_NOW_BOUGHT')
            : myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_WINNING_BID'),
        bidType: (!isClosed && bidTypeMessage) ? bidTypeMessage : false,
        catalogueItem: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CATALOGUE_ITEM'),
        catalogueLink: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CATALOGUE_GOTO_EXTERNAL_WEBSITE'),
        displayPrice: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_DISPLAY_PRICE'),
        recentlyAdded: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_RECENTLY_ADDED'),
        statusSeverity: userStatus.classes,
        timeLeft: (hasTimeLeft) ? myApp.ent.timedHelper.getBiddingAndTimeLeft(this.model.attributes, hasTimeLeft) : false,
        userStatus: userStatus.userStatusMessage
      },
      numberOrRef: myApp.ent.timedHelper.getNumberOrRef(this.model.attributes),
      remainingUnits: remainingUnits,
      staticPath: myApp.ent.config.get('staticBase'),
      totalUnits: totalUnits,
      model: this.model.toJSON()
    }
  },
  onRender: function () {
    if (this.model.get('timedBiddingInfo').publicVisible) {
      if (!this.model.get('itemType').catalogue) {
        this.renderTimedItemActions()
        this.bidActionsTicker = setInterval(this.renderTimedItemActions.bind(this), 1000)
      }
      if (this.model.get('itemType').supportsBidding) {
        this.$el.find('.x-auto-bid-input').val(this.autobidFieldContents)
      }
      if (!this.model.attributes.itemType.catalogue) {
        if (!this.model.get('manualClose') && !myApp.ent.timedHelper.isClosed(this.model.attributes)) {
          this.renderTimeLeftTicker()
          if (!this.timeLeftTicker) {
            this.timeLeftTicker = setInterval(this.renderTimeLeftTicker.bind(this), 1000)
          }
        }
      }
      this.$el.show()
    } else {
      this.$el.hide()
    }
    this.prettyPhoto()
  },
  onDestroy: function () {
    clearInterval(this.bidActionsTicker)
    clearInterval(this.timeLeftTicker)
  },
  handleSimilar: function (e) {
    e.preventDefault()
    window.location.href = myApp.ent.config.get('contextPath') + '/search?subCategory=' + this.model.get('category').id
    return false
  },
  handlePlaceBid: function (e) {
    e.preventDefault()
    var amount = $(e.currentTarget).data('amount')
    if (this.model.get('itemType').webcasty) {
      amount = amount * 100
    }
    var url = myApp.utils.ajax.getApiEndpoint('timed/bid') + '/' + this.model.get('id') + '/' + this.model.get('registrant').id + '/' + myApp.ent.user.get('id') + '/' + amount
    myApp.utils.ajax.postBean(null, url, _.bind(function (timedResponse) {
      if (timedResponse.models.ErrorModel) {
        myApp.ent.user.checkLogout(timedResponse)
        myApp.vent.trigger('ui:notification', { text: timedResponse.models.ErrorModel.message, level: 'warning' })
      }
    }, this))
    $(e.currentTarget).prop('disabled', true)
    return false
  },
  handleAutoBidKeyup: function (e) {
    e.preventDefault()
    var $input = $(e.currentTarget)
    if (e.keyCode !== 13) {
      $input.val(myApp.utils.types.toFormattedNumberNoDecimalLimited($input.val(), myApp.ent.user.attributes.thousandsSeparator))
      this.autobidFieldContents = $input.val()
    } else {
      this.placeAutoBid($input.val(), false)
    }
    return false
  },
  handleAutoBid: function (e) {
    e.preventDefault()
    var autoBidValue = $(e.currentTarget).parent().parent().find('.x-auto-bid-input').val()
    var reconfirmed = $(e.currentTarget).data('reconfirmed')
    this.placeAutoBid(e, autoBidValue, reconfirmed)
    return false
  },
  placeAutoBid: function (e, autoBidValue, reconfirmed) {
    e.preventDefault()
    if (!autoBidValue) return
    var amount = myApp.utils.types.toNumericCharsOnly(autoBidValue, myApp.ent.user.attributes.thousandsSeparator)
    var nextBid = myApp.ent.timedHelper.getAmountNextBid(this.model.attributes)
    if (this.model.get('itemType').webcasty) {
      amount = amount * 100
      nextBid = nextBid * 100
    }
    if (!reconfirmed && amount > (myApp.ent.config.get('confirmAmountThresholdMultiplier') * nextBid)) {
      this.renderTimedItemActions(true, autoBidValue)
      return
    }

    var url = myApp.utils.ajax.getApiEndpoint('timed/autobid') + '/' + this.model.get('id') + '/' + this.model.get('registrant').id + '/' + myApp.ent.user.get('id') + '/' + amount
    myApp.utils.ajax.postBean(null, url, _.bind(function (timedResponse) {
      this.autobidFieldContents = ''
      if (timedResponse.models.ErrorModel) {
        myApp.ent.user.checkLogout(timedResponse)
        myApp.vent.trigger('ui:notification', { text: timedResponse.models.ErrorModel.message, level: 'warning' })
      }
      if (timedResponse.models.BiddingItemModel) {
        this.model.set({
          'timedBiddingInfo': timedResponse.models.BiddingItemModel.timedBiddingInfo,
          'timedUserInfo': timedResponse.models.TimedUserInfoModel,
          rabbitCode: 102
        })
      }
    }, this))
    return false
  },
  renderCatalogue: function () {
    var model = new PageApp.Ent.BasicEntity({
      contextPath: myApp.ent.config.get('contextPath'),
      archived: this.model.attributes.archived,
      fullExternalWebpageUrl: this.model.attributes.fullExternalWebpageUrl,
      id: this.model.attributes.id
    })
    this.catalogueRegion.show(new PageApp.Views.CatalogueView({ model: model }))
  },
  renderTimeLeftTicker: function () {
    var endTime = myApp.ent.timedHelper.getActualOrNormalEndTime(this.model.attributes)
    var nowTime = myApp.ent.config.getNormalisedTimeMillis()
    var timeLeft = Math.round((endTime - nowTime) / 1000)
    var countdownMessage, seconds, hours, minutes
    if (myApp.ent.timedHelper.isClosed(this.model.attributes)) {
      this.model.attributes.timedBiddingInfo.overtimeBidding = false
      clearInterval(this.timeLeftTicker)
      this.model.set('manualClose', true)
      this.render()
    } else if (this.model.get('timedBiddingInfo').oneDayTimer) {
      countdownMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_COUNTDOWN_ENDS')
      seconds = Math.floor(timeLeft % 60)
      hours = Math.floor(timeLeft / (60 * 60))
      minutes = Math.floor(timeLeft / 60) - (hours * 60)
      if (hours > 0) {
        countdownMessage += ' ' + hours + ' hrs ' + ' ' + minutes + ' min ' + seconds + ' seconds'
      } else if (minutes > 0) {
        countdownMessage += ' ' + minutes + ' min ' + seconds + ' seconds'
      } else {
        countdownMessage += ' ' + seconds + ' sec'
      }
      this.$el.find('#timeLeft' + this.model.get('id')).text(countdownMessage)
    } else if (this.model.attributes.timedBiddingInfo.overtimeBidding) {
      if (timeLeft > 0) {
        seconds = Math.floor(timeLeft % 60)
        minutes = Math.floor(timeLeft / 60)
        countdownMessage = myApp.reqres.request('i16:getString', 'ItemMessageStatus_LOT_OVERTIME_MESSAGE')
        if (minutes > 0) {
          countdownMessage += ' ' + minutes + ' min ' + seconds + ' seconds'
        } else {
          countdownMessage += ' ' + seconds + ' sec'
        }
        this.$el.find('#timeLeft' + this.model.get('id'))
          .text(countdownMessage)
        this.$el.find('#timeLeftContainer' + this.model.get('id'))
          .removeClass('hidden')
      } else {
        this.$el.find('#timeLeftContainer' + this.model.get('id'))
          .addClass('hidden')
      }
    }
  },
  renderTimedItemActions: function (error, autoBidValue) {
    if (!this.timedItemActionsRegion || !this.timedItemActionsRegion.el) {
      this.addRegion('timedItemActionsRegion', '.x-region--lot-actions')
    }

    var timedBiddingInfo = this.model.get('timedBiddingInfo')

    var startTime = this.model.attributes.dateAuctionStarts
    var startTimeMs = startTime * 1000
    var nowTime = (myApp.ent.config.getNormalisedTimeMillis() / 1000)
    var timeUntilStart = startTime - nowTime

    var shouldBiddingBeDisabled = timeUntilStart > 0 && !this.model.get('itemType').webcasty && this.model.attributes.registrant.status !== 5 && this.model.attributes.registrant.id > 0
    var auctionNotStartedString = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_NOT_STARTED')
    auctionNotStartedString = auctionNotStartedString.replace('{0}', myApp.ent.timedHelper.getTimezonedDateString(startTimeMs, myApp.ent.user.getLocale(), this.model.attributes.timeZone.code))
    auctionNotStartedString = auctionNotStartedString.replace('{1}', myApp.ent.timedHelper.getTimezonedTimeString(startTimeMs, this.model.attributes.timeZone.code))

    if (!shouldBiddingBeDisabled) {
      clearInterval(this.bidActionsTicker)
    }

    var model = new PageApp.Ent.BasicEntity({
      amountNextBid: myApp.ent.timedHelper.getAmountNextBid(this.model.attributes),
      amountNextBidFormattedWithCurrency: myApp.ent.timedHelper.getAmountNextBidFormattedWithCurrency(this.model.attributes),
      auctionId: this.model.attributes.auctionId,
      auctionNotStartedString: auctionNotStartedString,
      autoBidValue: autoBidValue,
      availableForBidding: myApp.ent.timedHelper.isAvailableForBidding(this.model.attributes),
      clientOptions: this.model.attributes.clientOptions,
      closed: myApp.ent.timedHelper.isClosed(this.model.attributes),
      contactSellerEnabled: (timedBiddingInfo.contactSellerEnabled),
      contextPath: myApp.ent.config.get('contextPath'),
      title: this.model.get('localeSensitive').title,
      currencySymbol: this.model.get('localeSensitive').currency.symbol,
      error: error,
      highestAutoBid: this.model.attributes.timedUserInfo.highestAutoBid,
      highestAutoBidFormatted: myApp.ent.timedHelper.getHighestAutoBidFormatted(this.model.attributes),
      id: this.model.attributes.id,
      inLiveBidding: timedBiddingInfo.inLiveBidding,
      isLoggedIn: myApp.ent.user.isLoggedIn(),
      isPreBiddingEnabled: this.model.attributes.preBiddingEnabled,
      isWebcast: this.model.get('itemType').webcasty,
      numberOfUnits: this.model.attributes.timedBiddingInfo.numberOfUnits,
      offerModel: myApp.ent.timedHelper.getPlaceBidOfferModel(this.model.attributes),
      reconfirmed: error,
      registrant: this.model.attributes.registrant,
      shouldBiddingBeDisabled: shouldBiddingBeDisabled,
      showNumberOfUnits: (this.model.attributes.timedBiddingInfo.biddingTypePerUnit),
      statusId: timedBiddingInfo.statusId,
      supportsBidding: this.model.get('itemType').supportsBidding,
      timeUntilStart: timeUntilStart,
      winningOrWon: myApp.ent.timedHelper.isWinning(this.model.attributes) || myApp.ent.timedHelper.isWon(this.model.attributes),
      watching: this.model.attributes.watching
    })
    this.timedItemActionsRegion.show(new PageApp.Views.TimedItemActions({ model: model }))
  },
  prettyPhoto: function () {
    /* Pretty Photo */
    // WARNING Do NOT change youtube_link since it is used
    // in the aa_listing.description field
    // to enable videos of Lots to loaded in PrettyPhoto
    var prettyPhotoOptions = {
      show_title: false,
      social_tools: false,
      allow_resize: true,
      deeplinking: false,
      overlay_gallery: false
    }
    try {
      this.$el.find('.youtube_link').prettyPhoto(prettyPhotoOptions)
      this.$el.find("a[rel^='prettyPhoto']").prettyPhoto(prettyPhotoOptions)
    } catch (err) {}
  }
})
