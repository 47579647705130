PageApp.Views.ContactDetailsView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.contactDetails,
  ui: {
    addressLine1: '#addressLine1',
    addressLine2: '#addressLine2',
    city: '#city',
    countyOrState: '#countyOrState',
    postcode: '#postcode',
    country: '#country',
    timeZone: '#timeZone',
    phoneNumber: '#phoneNumber',
    mobileNumber: '#mobileNumber',
    faxNumber: '#faxNumber'
  },
  events: {
    'change #phoneNumber': 'showPhoneVerification',
    'change #mobileNumber': 'showPhoneVerification'
  },
  serializeData: function () {
    return {
      JspPublicCodes_JSP_CreateAccount_YourContactDetails: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_YourContactDetails'),
      JspPublicCodes_JSP_CreateAccount_AddressLine1: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_AddressLine1'),
      JspPublicCodes_JSP_CreateAccount_AddressLine2: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_AddressLine2'),
      JspPublicCodes_JSP_CreateAccount_City: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_City'),
      JspPublicCodes_JSP_CreateAccount_CountyOrState: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_CountyOrState'),
      JspPublicCodes_JSP_CreateAccount_PostcodeOrZip: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_PostcodeOrZip'),
      JspPublicCodes_JSP_CreateAccount_Country: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_Country'),
      JspPublicCodes_JSP_CreateAccount_TimeZone: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_TimeZone'),
      JspPublicCodes_JSP_CreateAccount_Required_Field: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_Required_Field'),
      JspPublicCodes_JSP_SHORT_TELEPHONE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_SHORT_TELEPHONE'),
      JspPublicCodes_JSP_SHORT_MOBILE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_SHORT_MOBILE'),
      JspPublicCodes_JSP_SHORT_FAX: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_SHORT_FAX'),
      JspPublicCodes_JSP_LABEL_SELECT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LABEL_SELECT'),
      referenceCountries: this.model.get('referenceData').referenceCountries,
      referenceTimeZones: this.model.get('referenceData').referenceTimeZones,
      inputAddressLine1: this.model.get('accountManagementFormModel').contactDetails.addressLine1,
      inputAddressLine2: this.model.get('accountManagementFormModel').contactDetails.addressLine2,
      inputCity: this.model.get('accountManagementFormModel').contactDetails.city,
      inputCountyOrState: this.model.get('accountManagementFormModel').contactDetails.countyOrState,
      inputPostcode: this.model.get('accountManagementFormModel').contactDetails.postcode,
      inputPhoneNumber: this.model.get('accountManagementFormModel').contactDetails.phoneNumber,
      inputMobileNumber: this.model.get('accountManagementFormModel').contactDetails.mobileNumber,
      inputFaxNumber: this.model.get('accountManagementFormModel').contactDetails.faxNumber
    }
  },

  onRender: function () {
    this.ui.country.val(this.model.get('accountManagementFormModel').contactDetails.country)
    this.ui.timeZone.val(this.model.get('accountManagementFormModel').contactDetails.timeZone)
  },

  getUpdatedModel: function () {
    this.updateModelWithInput()
    return this.model
  },

  updateModelWithInput: function () {
    this.model.get('accountManagementFormModel').contactDetails.addressLine1 = this.ui.addressLine1.val().trim()
    this.model.get('accountManagementFormModel').contactDetails.addressLine2 = this.ui.addressLine2.val().trim()
    this.model.get('accountManagementFormModel').contactDetails.city = this.ui.city.val().trim()
    this.model.get('accountManagementFormModel').contactDetails.countyOrState = this.ui.countyOrState.val().trim()
    this.model.get('accountManagementFormModel').contactDetails.postcode = this.ui.postcode.val().trim()
    this.model.get('accountManagementFormModel').contactDetails.country = this.ui.country.val().trim()
    this.model.get('accountManagementFormModel').contactDetails.timeZone = this.ui.timeZone.find('option:selected').text().indexOf('===') === 0 ? '' : this.ui.timeZone.val()
    this.model.get('accountManagementFormModel').contactDetails.phoneNumber = this.ui.phoneNumber.val().trim()
    this.model.get('accountManagementFormModel').contactDetails.mobileNumber = this.ui.mobileNumber.val().trim()
    this.model.get('accountManagementFormModel').contactDetails.faxNumber = this.ui.faxNumber.val().trim()
  },

  showPhoneVerification: function () {
    myApp.vent.trigger('input:showPhoneVerification', true)
  }
})
