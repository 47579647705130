PageApp.Views.MySettingsLayoutView = Backbone.Marionette.LayoutView.extend({
  template: hbs.templates.mySettings,
  regions: {
    contactDetailsRegion: '#contactDetailsView',
    personalDetailsRegion: '#personalDetailsView',
    userPreferencesRegion: '#userPreferencesView'
  },
  initialize: function (options) {
    this.options = options
  },
  serializeData: function () {
    return {
      JspPublicCodes_JSP_CreateAccount_TITLE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_TITLE'),
      JspPublicCodes_JSP_CreateAccount_PersonalDetails: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_PersonalDetails'),
      JspPublicCodes_JSP_MY_SETTINGS_VERIFIED_LOCATION: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SETTINGS_VERIFIED_LOCATION'),
      JspPublicCodes_JSP_MY_SETTINGS_VERIFIED_CARD_ENDING: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SETTINGS_VERIFIED_CARD_ENDING'),
      JspPublicCodes_JSP_MY_SETTINGS_VERIFIED_CARD_EXPIRATION: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SETTINGS_VERIFIED_CARD_EXPIRATION'),
      JspPublicCodes_JSP_MY_SETTINGS_CHANGE_CARD_TEXT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SETTINGS_CHANGE_CARD_TEXT'),
      JspPublicCodes_JSP_MY_SETTINGS_CHANGE_CARD_LINK: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SETTINGS_CHANGE_CARD_LINK'),
      JspPublicCodes_JSP_MY_SETTINGS_NO_LOCATION_VERIFIED_TEXT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SETTINGS_NO_LOCATION_VERIFIED_TEXT'),
      JspPublicCodes_JSP_MY_SETTINGS_NO_LOCATION_VERIFIED_LINK: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SETTINGS_NO_LOCATION_VERIFIED_LINK'),
      JspPublicCodes_JSP_CreateAccount_SAVE_SETTINGS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_SAVE_SETTINGS'),
      JspPublicCodes_JSP_CreateAccount_NOT_LOGGED_IN: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_NOT_LOGGED_IN')
    }
  },
  events: {
    'click .x-submit': 'handleSubmit',
    'keyup :text': 'handleSubmitFromInput',
    'click .x-cardVerification': 'forwardToCardVerification'
  },

  onRender: function () {
    if (myApp.ent.user.attributes.statusId === 7) {
      var message = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SETTINGS_INCOMPLETE_ACCOUNT_INFO')
      myApp.vent.trigger('ui:notification', { text: message, level: 'warning', sticky: true })
    }
    this.contactDetailsRegion.show(new PageApp.Views.ContactDetailsView({ model: this.model }))
    this.userPreferencesRegion.show(new PageApp.Views.UserPreferencesView({ model: this.model }))
    this.personalDetailsRegion.show(new PageApp.Views.PersonalDetailsView({ model: this.model }))
  },

  handleSubmitFromInput: function (e) {
    e.preventDefault()
    if (e.keyCode === 13) {
      this.handleSubmit(e)
    }
    return false
  },

  updateModelWithInput: function () {
    this.model.set('contactDetails', this.contactDetailsRegion.currentView.getUpdatedModel())
    this.model.set('personalDetails', this.personalDetailsRegion.currentView.getUpdatedModel())
    this.model.set('userPreferences', this.userPreferencesRegion.currentView.getUpdatedModel())
  },

  handleSubmit: function (e) {
    e.preventDefault()
    this.updateModelWithInput()
    var baseUrl = encodeURIComponent(window.location.href)
    myApp.utils.ajax.postBean(this.model.attributes.accountManagementFormModel, myApp.utils.ajax.getApiEndpoint('mySettings') + '?baseUrl=' + baseUrl, function (response) {
      var errors = response.errors
      if (typeof errors !== 'undefined' && errors !== null && errors.length !== 0) {
        myApp.utils.validation.renderErrors(new PageApp.Ent.Errors(errors))
      } else if (response.failed) {
        myApp.utils.validation.renderErrors(new PageApp.Ent.Errors(errors))
      } else {
        myApp.vent.trigger('account:mysettings:success', response)
      }
    })

    return false
  },

  clearErrors: function (e) {
    var $targetField = $(e.currentTarget)
    myApp.utils.validation.clearFieldError($targetField)
  }

})
