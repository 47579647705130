PageApp.Views.MySettingsSuccessView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.mySettingsSuccess,
  serializeData: function () {
    return {
      JspPublicCodes_JSP_MY_SETTINGS_CHANGES_SUCCESSFUL: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SETTINGS_CHANGES_SUCCESSFUL'),
      JspPublicCodes_JSP_MY_SETTINGS_CHANGES_APPLIED: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SETTINGS_CHANGES_APPLIED'),
      JspPublicCodes_JSP_MY_SETTINGS_BACK_TO_HOMEPAGE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SETTINGS_BACK_TO_HOMEPAGE'),
      JspPublicCodes_JSP_BACK_TO_PREVIOUS_PAGE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BACK_TO_PREVIOUS_PAGE'),
      contextPath: myApp.ent.config.attributes.contextPath
    }
  }
})
