PageApp.Views.RegisterView = Backbone.Marionette.ItemView.extend({
  getTemplate: function () {
    return hbs.templates.register
  },
  events: {
    'keyup .x-add-commas': 'validateSpendingLimit',
    'click .x-submit': 'handleRegister',
    'click #auctionTerms': 'showAuctionTerms'
  },
  serializeData: function () {
    var label1 = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_REGISTRATION_SPENDING_LIMIT')
    if (this.model.get('hasError1')) {
      label1 = this.model.get('error1')
    }

    var label2 = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_REGISTRATION_TS_AND_CS_READ') +
      ' ' + myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_REGISTRATION_TS_AND_CS') +
      ' ' + myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_REGISTRATION_TS_AND_CS_ACCEPT')
    if (this.model.get('hasError2')) {
      label2 = this.model.get('error2')
    }

    var logoDims = { width: 275, height: 210 }
    logoDims.operation = 'pad'

    var content = {
      logoDims: logoDims,
      hasError1: this.model.get('hasError1'),
      label1: label1,
      hasError2: this.model.get('hasError2'),
      label2: label2,
      contextPath: myApp.ent.config.get('contextPath'),
      JspPublicCodes_JSP_AUCTION_REGISTRATION_REGISTER: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_REGISTRATION_REGISTER'),
      JspPublicCodes_JSP_AUCTION_REGISTRATION: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_REGISTRATION'),
      JspPublicCodes_JSP_AUCTIONEER_STRIP_SALEMGTBY: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTIONEER_STRIP_SALEMGTBY'),
      UserCodes_TERMS_NOT_AGREED: myApp.reqres.request('i16:getString', 'UserCodes_TERMS_NOT_AGREED'),
      JSTextCodes_FIELD_NOT_COMPLETE: myApp.reqres.request('i16:getString', 'JSTextCodes_FIELD_NOT_COMPLETE')
    }
    _.extend(content, this.model.attributes)
    return content
  },
  showAuctionTerms: function (e) {
    e.preventDefault()
    var model = new PageApp.Ent.BasicEntity({ content: this.model.get('auctionTerms') })
    myApp.myModalRegion.show(new PageApp.Views.ModalLayout({ view: new PageApp.Views.TermsAndConditionsModalView({ model: model }) }))
  },
  validateSpendingLimit: function (e) {
    e.preventDefault()
    var $input = $(e.currentTarget)
    if (e.keyCode !== 13) {
      $input.val(myApp.utils.types.toFormattedNumberLimited($input.val(), myApp.ent.user.attributes.thousandsSeparator))
    }
    return false
  },
  handleRegister: function (e) {
    e.preventDefault()
    var agreedTerms = this.$el.find('#agreedTerms').prop('checked')
    this.model.set('agreedTerms', agreedTerms)
    var valid = agreedTerms
    this.model.set('hasError2', null)
    if (!agreedTerms) {
      this.model.set('hasError2', 'has-error')
      this.model.set('error2', myApp.reqres.request('i16:getString', 'UserCodes_TERMS_NOT_AGREED'))
    }
    if (this.model.get('spendingLimitRequired')) {
      var spendingLimit = this.$el.find('#spendingLimit').val()
      this.model.set('spendingLimit', spendingLimit)

      try {
        spendingLimit = myApp.utils.types.toNumber(spendingLimit)
        this.model.set('spendingLimit', spendingLimit)
        this.model.set('hasError1', false)
      } catch (err) {
        this.model.set('hasError1', 'has-error')
        this.model.set('error1', myApp.reqres.request('i16:getString', 'InfrastructureCodes_FAILED_TO_CONVERT_STRING_TO_NUMBER'))
        valid = false
      }
    }
    if (!valid) {
      e.preventDefault()
      this.render()
      return false
    }
    var bean = {
      '@class': 'com.bidlogix.scaffold.blade.RegisterBladeModel',
      userId: myApp.ent.user.get('id'),
      spendingLimit: this.model.get('spendingLimit'),
      agreedTerms: this.model.get('agreedTerms'),
      auctionId: this.model.get('auctionId'),
      referer: this.model.get('referer')
    }
    myApp.myModalRegion.show(new PageApp.Views.WaitingView())
    myApp.utils.ajax.postBean(bean, myApp.utils.ajax.getApiEndpoint('registerForAuction'), _.bind(function (response) {
      myApp.myModalRegion.empty()
      myApp.vent.trigger('register:success', response)
      if (response.title) {
        document.title = response.title
      }
    }, this))
  }
})
