PageApp.Views.TermsAndConditionsView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.termsAndConditions,
  ui: {
    agreedTsAndCsAndPrivacyPolicy: '#agreedTsAndCsAndPrivacyPolicy'
  },
  events: {
    'click #termsConditions': 'showTerms',
    'click #privacyPolicy': 'showPrivacy'
  },
  onRender: function () {
    this.fetchContent('terms')
    this.fetchContent('privacy')
  },
  serializeData: function () {
    return {
      JspPublicCodes_JSP_FOOTER_TS_AND_CS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_FOOTER_TS_AND_CS'),
      JspPublicCodes_JSP_FOOTER_PRIVACY_POLICY: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_FOOTER_PRIVACY_POLICY'),
      JspPublicCodes_JSP_CreateAccount_AgreeTerms: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_AgreeTerms'),
      contextPath: myApp.ent.config.attributes.contextPath
    }
  },
  fetchContent: function (page) {
    var $elf = this
    myApp.utils.ajax.get({ page: page }, myApp.utils.ajax.getApiEndpoint('content') + '/plain', function (response) {
      if (!$elf.options) $elf.options = {} // hack alert: adding to objects as the model is being listened to by other views..
      $elf.options[page] = response.content
      $elf.$el.find('#' + page + '-content-body').html(response.content)
    })
  },
  showTerms: function (e) {
    e.preventDefault()
    var model = new PageApp.Ent.BasicEntity({ content: this.options['terms'] })
    myApp.myModalRegion.show(new PageApp.Views.ModalLayout({ view: new PageApp.Views.TermsAndConditionsModalView({ model: model }) }))
  },
  showPrivacy: function (e) {
    e.preventDefault()
    var model = new PageApp.Ent.BasicEntity({ content: this.options['privacy'] })
    myApp.myModalRegion.show(new PageApp.Views.ModalLayout({ view: new PageApp.Views.TermsAndConditionsModalView({ model: model }) }))
  }

})
