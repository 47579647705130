PageApp.Views.TenderView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.tender,
  events: {
    'click .x-tender': 'handleTender',
    'click .x-tender-pct': 'handleTenderPct',
    'keyup .x-add-commas': 'handleTenderKeyup',
    'keyup .x-decimal-add-commas': 'handleTenderKeyup'
  },
  modelEvents: {
    'change': 'render'
  },
  serializeData: function () {
    var label1 = this.model.get('label1')
    if (!label1) {
      label1 = myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_ID_LIKE_TO_OFFER')
    }
    var error1Class
    if (this.model.get('error1')) {
      label1 = myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_PLEASE_ENTER_AMOUNT')
      error1Class = 'has-error'
    }
    var bp = this.model.attributes.buyersPremium
    var hasBp = bp && bp > 0

    var isTenderPct = this.model.attributes.isTenderPct
    var helpTitle
    var helpContent
    var action
    var maxAmountLength
    if (isTenderPct) {
      helpTitle = myApp.reqres.request('i16:getString', 'PageCodes_PAGE_TENDER_PCT_HELP_TITLE')
      helpContent = myApp.reqres.request('i16:getString', 'PageCodes_PAGE_TENDER_PCT_HELP')
      action = 'x-tender-pct'
      maxAmountLength = 5 // i.e. upto 99.99
    } else {
      helpTitle = myApp.reqres.request('i16:getString', 'PageCodes_PAGE_TENDER_HELP_TITLE')
      helpContent = myApp.reqres.request('i16:getString', 'PageCodes_PAGE_TENDER_HELP')
      action = 'x-tender'
      maxAmountLength = 16
    }

    var content = {
      label1: label1,
      error1Class: error1Class,
      helpTitle: helpTitle,
      helpContent: helpContent,
      hasBp: hasBp,
      maxAmountLength: maxAmountLength,
      JSTextCodes_PROCEED: myApp.reqres.request('i16:getString', 'JSTextCodes_PROCEED'),
      JSTextCodes_CLOSE: myApp.reqres.request('i16:getString', 'JSTextCodes_CLOSE'),
      JspPublicCodes_PURCHASE_DIALOG_TENDER_TITLE: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_TENDER_TITLE'),
      JspPublicCodes_PURCHASE_DIALOG_TITLE: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_TITLE'),
      JspPublicCodes_PURCHASE_DIALOG_BUYERS_PREMIUM_AT: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_BUYERS_PREMIUM_AT'),
      JspPublicCodes_PURCHASE_DIALOG_PRICE: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_PRICE'),
      JspPublicCodes_JSP_BIDDING_NUMBER_OF_UNITS_FOR_TENDER: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_NUMBER_OF_UNITS_FOR_TENDER'),
      JspPublicCodes_JSP_BIDDING_UNIT_OF_MEASUREMENT_PER: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_UNIT_OF_MEASUREMENT_PER'),
      action: action
    }
    content = _.extend(content, this.model.attributes)
    return content
  },
  handleTenderKeyup: function (e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      var isTenderPct = this.model.attributes.isTenderPct
      if (isTenderPct) {
        this.handleTenderPct()
      } else {
        this.handleTender()
      }
      return false
    }
  },
  onRender: function () {
    if (this.model.get('offerAmount') && this.model.get('offerAmount') > 0) {
      var $input = this.$el.find('#offerAmount')
      if ($input) {
        $input.val(myApp.utils.types.toFormattedNumber($input.val(), myApp.ent.user.attributes.thousandsSeparator))
      }
    }
  },
  isInValid: function (isPercentage) {
    var offerAmount = this.$el.find('#offerAmount').val()
    var error1
    try {
      offerAmount = myApp.utils.types.toDecimalNumber(offerAmount)
      if (!offerAmount || offerAmount.length === 0 || offerAmount < 0) {
        error1 = true
      } else if (isPercentage) {
        if (offerAmount > 100) {
          error1 = true
        }
      }
    } catch (err) {
      error1 = true
    }
    var silent = true
    if (error1) {
      silent = false
    }
    this.model.set({
      'offerAmount': offerAmount,
      'error1': error1
    }, { silent: silent })
    return error1
  },
  handleTender: function (e, isTenderPct) {
    var tenderTypeForRequest = isTenderPct ? 'tenderPct' : 'tender'
    if (e) e.preventDefault()
    if (this.isInValid(isTenderPct)) {
      myApp.vent.trigger('ui:notification', { text: 'Please correct the errors and try again..', level: 'warning' })
      return
    }
    // Note: BuyNowRequestModel appears to be used as @class for all offer types.
    var bean = {
      '@class': 'com.bidlogix.scaffold.models.bidding.BuyNowRequestModel',
      itemId: this.model.attributes.id,
      registrantId: this.model.attributes.registrantId,
      amount: this.model.get('offerAmount'),
      userId: myApp.ent.user.get('id')
    }
    myApp.utils.ajax.postBean(bean, myApp.utils.ajax.getApiEndpoint('timed/' + tenderTypeForRequest), _.bind(function (response) {
      if (response.models.BiddingItemModel) {
        var itemModel = myApp.request('reqres:timed:set:timedBiddingInfo', { models: response.models, rabbitCode: response.actionCode })
        myApp.ent.purchaseEntity.setModelForTender(itemModel.attributes, true)
        if (response.models.ErrorModel) {
          myApp.vent.trigger('ui:notification', { text: response.models.ErrorModel.message, level: 'warning' })
        }
      } else if (response.models.ErrorModel) {
        myApp.ent.user.checkLogout(response)
        myApp.vent.trigger('ui:notification', { text: response.models.ErrorModel.message, level: 'warning' })
        this.model.set({ 'hasResult': true, 'resultClass': 'text-danger', 'resultMessage': response.models.ErrorModel.message })
      }
      myApp.vent.trigger('layouthacks:heights')
    }, this))
    return false
  },
  handleTenderPct: function (e) {
    return this.handleTender(e, true)
  }
})
