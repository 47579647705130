PageApp.Views.PersonalDetailsView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.personalDetails,
  ui: {
    forename: '#forename',
    surname: '#surname',
    companyName: '#companyName',
    jobTitle: '#jobTitle',
    vatNumber: '#vatNumber'
  },
  serializeData: function () {
    return {
      JspPublicCodes_JSP_CreateAccount_PersonalDetails: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_PersonalDetails'),
      JspPublicCodes_JSP_LABEL_SELECT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LABEL_SELECT'),
      JspPublicCodes_JSP_LABEL_VAT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LABEL_VAT'),
      JspPublicCodes_JSP_CreateAccount_Forename: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_Forename'),
      JspPublicCodes_JSP_CreateAccount_Surname: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_Surname'),
      JspPublicCodes_JSP_CreateAccount_CompanyName: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_CompanyName'),
      JspPublicCodes_JSP_CreateAccount_JobTitle: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_JobTitle'),
      JspPublicCodes_JSP_CreateAccount_Required_Field: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_Required_Field'),
      requestVatNumber: myApp.ent.webApp.attributes.requestVatNumber,
      inputForename: this.model.get('accountManagementFormModel').personalDetails.forename,
      inputSurname: this.model.get('accountManagementFormModel').personalDetails.surname,
      inputCompanyName: this.model.get('accountManagementFormModel').personalDetails.companyName,
      inputJobTitle: this.model.get('accountManagementFormModel').personalDetails.jobTitle,
      inputVatNumber: this.model.get('accountManagementFormModel').personalDetails.vatNumber
    }
  },

  getUpdatedModel: function () {
    this.updateModelWithInput()
    return this.model
  },

  updateModelWithInput: function () {
    this.model.get('accountManagementFormModel').personalDetails.forename = this.ui.forename.val().trim()
    this.model.get('accountManagementFormModel').personalDetails.surname = this.ui.surname.val().trim()
    this.model.get('accountManagementFormModel').personalDetails.companyName = this.ui.companyName.val().trim()
    this.model.get('accountManagementFormModel').personalDetails.jobTitle = this.ui.jobTitle.val().trim()
    if (myApp.ent.webApp.attributes.requestVatNumber) {
      this.model.get('accountManagementFormModel').personalDetails.vatNumber = this.ui.vatNumber.val().trim()
    }
  }
})
