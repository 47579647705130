PageApp.Ent.TimedItems = Backbone.Collection.extend({
  model: PageApp.Ent.TimedItem,
  initialise: function (options) {
  },
  defaults: {
  },
  getLongPollUrl: function () {
    if (!this.models || _.size(this.models) === 0) {
      return
    }
    var itemIdMap = {}
    for (var i = 0; i < this.models.length; i++) {
      itemIdMap[this.models[i].attributes.id] = this.models[i].attributes.timedBiddingInfo.version
    }
    return { 'itemIdMap': itemIdMap }
  },
  getItemIds: function () {
    if (!this.models || _.size(this.models) === 0) {
      return
    }
    var itemIds = []
    for (var i = 0; i < this.models.length; i++) {
      itemIds.push(this.models[i].attributes.id)
    }
    return itemIds
  }
})
