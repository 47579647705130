PageApp.AjaxUtils = function () {
  function appendUniqueId (action, params) {
    // fix
    var url = myApp.ent.config.get('apiBase') + action
    if (action.indexOf('myBids') > -1) {
      return url
    }
    if (params) {
      for (var key in params) {
        var param = params[key]
        url += '/' + param
      }
    }
    var randVal = (new Date()).getTime() + Math.floor((Math.random() * 1000000) + 1)
    if (url.indexOf('?') === -1) {
      url += '?uid=' + myApp.ent.config.get('uid') + '&nocache=' + randVal
    } else {
      url += '&uid=' + myApp.ent.config.get('uid') + '&nocache=' + randVal
    }
    return url
  }

  function getConfig (action, params, callback, method) {
    var url = appendUniqueId(action, params)
    return {
      url: url,
      action: action,
      method: (!method) ? 'GET' : method,
      successHandler: callback,
      errorHandler: callback
    }
  }

  function getConfigPostBean (action, bean, callback, method) {
    var url = appendUniqueId(action)
    return {
      url: url,
      action: action,
      data: bean,
      method: (!method) ? 'GET' : method,
      successHandler: callback,
      errorHandler: callback
    }
  }

  function getHeaders () {
    var headers = { 'X-Forwarded-client-Id': myApp.ent.config.attributes.clientId.substring(1) }
    var token = myApp.utils.token.getToken()
    if (token) {
      headers.Authorization = 'bearer ' + token
    }
    return headers
  }

  function logoutUser () {
    if (myApp.utils.token.getToken()) {
      myApp.vent.trigger('ui:notification', { sticky: true, text: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LOGIN_SESSION_ERROR'), level: 'danger' })
    } else {
      myApp.vent.trigger('ui:notification', { sticky: true, text: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_NOT_LOGGED_IN'), level: 'danger' })
    }
    myApp.router.navigate('#!/logout', { trigger: true, replace: true })
  }

  function sendToServer (config, skipTokenCheck) {
    var ajaxConfig = function () {
      return {
        url: config.url,
        beforeSend: (config && config.headers) ? function (xhr) { xhr.setRequestHeader(config.headers[0].name, config.headers[0].value) } : function noop () {},
        contentType: (config.contentType) ? config.contentType : 'application/json',
        dataType: (config.dataType) ? config.dataType : 'json',
        method: (config.method) ? config.method : 'POST',
        timeout: 60000, // timeout 60s note the server side timeout is 50s so the server should expire first.
        data: JSON.stringify(config.data),
        headers: getHeaders(),
        success: function (response) {
          if (config.successHandler) {
            try {
              if (response.actionCode && myApp.ent.status && response.actionCode === myApp.ent.status.getRabbitResponseByName('PUBLIC_RELOAD').id) {
                myApp.vent.trigger('ui:notification', { sticky: true, text: 'Please reload this page.', level: 'danger' })
                return
              }
            } catch (err) {}
            config.successHandler(response)
          }
        },
        error: function (response, textStatus, errorThrown) {
          switch (response.status) {
            case 401:
              // skipTokenCheck is used for SSO endpoints, which can return a 401 and still allow the user to browse the site.
              // in this case we do not want to "log the user out", since they are not logged in.
              if (!skipTokenCheck) {
                if (myApp.utils.token.getToken()) {
                  myApp.vent.trigger('ui:notification', { sticky: true, text: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LOGIN_SESSION_ERROR'), level: 'danger' })
                } else {
                  myApp.vent.trigger('ui:notification', { sticky: true, text: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_NOT_LOGGED_IN'), level: 'danger' })
                }
                if (response.responseJSON.error === 'invalid_token') {
                  console.warn('Invalid token, logging out')
                  window.localStorage.removeItem('bidJSToken')
                  window.location.reload()
                  return
                }
                logoutUser()
              }
              break

            case 403:
              if (config.errorHandler) {
                return config.errorHandler(response.responseJSON)
              } else if (config.successHandler) {
                return config.successHandler(response.responseJSON)
              }
              break

            case 404:
              myApp.mainRegion.show(new PageApp.Views.ErrorNotFoundView({ model: myApp.ent.fourOFour }))
          }

          try {
            if (response.actionCode === myApp.ent.status.getRabbitResponseByName('PUBLIC_RELOAD').id) {
              myApp.vent.trigger('ui:notification', { sticky: true, text: 'Please reload this page.', level: 'danger' })
              return
            }
          } catch (err) {}
          if (errorThrown === 'timeout') {
            myApp.vent.trigger('timed:poll')
          }
          if (!response) {
            response = { failed: true, worked: false, message: 'server error - please reload' }
          }
          if (!response.failed || !response.worked) {
            // if we failed, merge in the response JSON so we can deal with validation errors etc
            response = Object.assign({}, response.responseJSON, { failed: true, worked: false })
          }
          try {
            if (config.errorHandler) {
              config.errorHandler(response)
            } else if (config.successHandler) {
              config.successHandler(response)
            }
          } catch (err) {
            if (response && response.message) {
              myApp.vent.trigger('ui:notification', { sticky: true, text: response.message, level: 'danger' })
            } else {
              myApp.vent.trigger('ui:notification', { sticky: true, text: 'server error - please refresh this page', level: 'danger' })
            }
          }
        }
      }
    }

    if (myApp.utils.oAuth.isUsingOAuth()) {
      myApp.utils.oAuth.parse(skipTokenCheck)
        .then(function () {
          return $.ajax(ajaxConfig())
        })
        .catch(function () {
          return $.ajax(ajaxConfig())
        })
    } else {
      return $.ajax(ajaxConfig())
    }
  }

  return {
    getApiEndpoint: function (endpointName) {
      if (window.apiConfiguration) {
        var version = window.apiConfiguration[endpointName]
      }
      var base = (version) ? '/v' + version + '/' : '/'
      return base + endpointName
    },
    get: function (params, action, callback) {
      var config = getConfig(action, params, callback, 'GET')
      return sendToServer(config)
    },
    getDirect: function (config, handler) {
      config.successHandler = handler
      config.errorHandler = handler
      return sendToServer(config)
    },
    post: function (params, action, callback) {
      var config = getConfig(action, params, callback, 'POST')
      return sendToServer(config)
    },
    postDirect: function (config, skipTokenCheck) {
      if (skipTokenCheck) {
        skipTokenCheck = true
      }
      return sendToServer(config, skipTokenCheck)
    },
    postBean: function (bean, action, callback) {
      var config = getConfigPostBean(action, bean, callback, 'POST')
      return sendToServer(config)
    },
    postPut: function (bean, action, callback) {
      var method = 'POST' // save new object
      if (bean && bean.id && bean.id > 0) {
        method = 'PUT' // update existing object
      }
      var config = getConfigPostBean(action, bean, callback, method)
      return sendToServer(config)
    },
    relocate: function (pathname) {
      var page = myApp.ent.config.get('page')
      if (page === 'searchland' || page === 'marketland' || page === 'auctionland') {
        if (pathname && pathname.startsWith('#')) {
          pathname = pathname.substring(1)
        }
        myApp.router.navigate(pathname, { trigger: true })
      } else {
        var protocol = myApp.ent.config.get('protocol')
        if (window.location.protocol !== 'https:') {
          window.location = protocol + '://' + window.location.hostname + pathname
        } else {
          window.location = pathname
        }
      }
    },
    updatePageMetaModel: function (metamodel) {
      document.title = metamodel.metaTitle
      $('meta[name="title"]').attr('content', metamodel.metaTitle)
      $('meta[name="description"]').attr('content', metamodel.metaDescription)
    }
  }
}
