PageApp.Views.RegisterSuccessView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.registerSuccess,
  serializeData: function () {
    var content = {
      contextPath: myApp.ent.config.get('contextPath'),
      JspPublicCodes_JSP_BIDDING_PAY_DEPOSIT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_PAY_DEPOSIT')
    }
    _.extend(content, this.model.attributes)
    return content
  },
  events: {
  }

})
