PageApp.Views.UsernamePasswordView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.usernamePassword,
  initialize: function (options) {
    this.options = options
  },
  actions: {
    accountValidation: '/createAccountValidation'
  },
  ui: {
    username: '#username',
    email: '#email',
    repeatEmail: '#repeatEmail',
    password: '#password',
    repeatPassword: '#repeatPassword',
    passwordConfirm: '.x-confirm',
    progress: '.x-progress'
  },
  events: {
    'blur #username': 'checkUsernameExists',
    'blur #email': 'checkEmailExists'
  },
  serializeData: function () {
    return {
      JspPublicCodes_JSP_CreateAccount_YourAccount: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_YourAccount'),
      JspPublicCodes_JSP_CreateAccount_Username: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_Username'),
      JspPublicCodes_JSP_CreateAccount_Email: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_Email'),
      JspPublicCodes_JSP_CreateAccount_RepeatEmail: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_RepeatEmail'),
      JspPublicCodes_JSP_CreateAccount_Password: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_Password'),
      JspPublicCodes_JSP_CreateAccount_Strength: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_Strength'),
      JspPublicCodes_JSP_CreateAccount_Not_Enough_Characters: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_Not_Enough_Characters'),
      JspPublicCodes_JSP_PASSWORD_STRENGTH_WEAK: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PASSWORD_STRENGTH_WEAK'),
      JspPublicCodes_JSP_PASSWORD_STRENGTH_OK: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PASSWORD_STRENGTH_OK'),
      JspPublicCodes_JSP_PASSWORD_STRENGTH_GOOD: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PASSWORD_STRENGTH_GOOD'),
      JspPublicCodes_JSP_PASSWORD_STRENGTH_EXCELLENT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PASSWORD_STRENGTH_EXCELLENT'),
      JspPublicCodes_JSP_CreateAccount_RepeatPassword: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_RepeatPassword'),
      JspPublicCodes_JSP_CreateAccount_Required_Field: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_Required_Field'),
      JspPublicCodes_JSP_LABEL_SELECT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LABEL_SELECT')
    }
  },
  onRender: function () {
    var $cpvElf = this
    this.ui.password.complexify({}, function callback (valid, complexity) {
      $cpvElf.ui.progress.css('width', complexity + '%')
      if (complexity >= 30) {
        $cpvElf.ui.progress.addClass('progress-bar-success')
      } else {
        $cpvElf.ui.progress.removeClass('progress-bar-success')
      }
    })
  },

  checkEntryExists: function (label, trimmedValue) {
    // '@class': 'com.ipsc.infrastructure.control.util.LabelValueBean',
    var data = {
      label: label,
      value: trimmedValue
    }
    myApp.utils.ajax.postBean(data, myApp.utils.ajax.getApiEndpoint('createAccountValidation'), function (response) {
      var errors = response.errors
      if (typeof errors !== 'undefined' && errors !== null && errors.length !== 0) {
        myApp.utils.validation.renderErrors(new PageApp.Ent.Errors(errors))
      }
    })
  },

  checkUsernameExists: function (e) {
    var $targetField = $(e.currentTarget)
    var trimmedUsername = $.trim($targetField.val())
    if (myApp.utils.validation.isValidUsername(trimmedUsername)) {
      this.checkEntryExists('username', trimmedUsername)
    } else {
      var errors = new PageApp.Ent.Errors()
      errors.add({
        field: 'username',
        code: 'UserCodes_USERNAME_INCORRECTLY_FORMATTED'
      })
      myApp.utils.validation.renderErrors(errors)
    }
  },

  checkEmailExists: function (e) {
    var $targetField = $(e.currentTarget)
    var trimmedEmail = $.trim($targetField.val())
    if (myApp.utils.validation.isValidEmail(trimmedEmail)) {
      this.checkEntryExists('email', trimmedEmail)
    }
  }

})
