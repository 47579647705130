PageApp.Views.UserPreferencesView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.userPreferences,
  initialize: function (options) {
    this.options = options
  },
  ui: {
    dataAnalyticsOk: '#dataAnalyticsOk', receiveEmailsOk: '#receiveEmailsOk', outbidEmailsOk: '#outbidEmailsOk'
  },
  serializeData: function () {
    return {
      JspPublicCodes_JSP_DATA_ANALYTICS_OPT_IN: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_DATA_ANALYTICS_OPT_IN'),
      JspPublicCodes_JSP_EMAIL_OPT_IN: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_EMAIL_OPT_IN'),
      JspPublicCodes_JSP_SEND_OUTBID_EMAILS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_SEND_OUTBID_EMAILS'),
      JspPublicCodes_JSP_NEWS_FROM_APEX_PARTNERS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_NEWS_FROM_APEX_PARTNERS'),
      JspPublicCodes_JSP_CreateAccount_YourPrefs: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_YourPrefs'),
      JspPublicCodes_JSP_CreateAccount_HowHearUs: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_HowHearUs'),
      JspPublicCodes_JSP_LABEL_SELECT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LABEL_SELECT'),
      JspPublicCodes_JSP_AREA_OF_PREFERENCE_DESCRIPTION: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AREA_OF_PREFERENCE_DESCRIPTION'),
      JspPublicCodes_JSP_LABEL_SELECT_ALL: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LABEL_SELECT_ALL'),
      showNewsFromPartners: false
    }
  },
  onRender: function () {
    this.ui.dataAnalyticsOk.prop('checked', this.model.attributes.accountManagementFormModel.userPreferences.dataAnalyticsOk)
    this.ui.receiveEmailsOk.prop('checked', this.model.attributes.accountManagementFormModel.userPreferences.receiveEmailsOk)
  },
  clearReceiveEmailsOKError: function () {
    myApp.utils.validation.clearFieldError($('#receiveEmailsOk'))
  },

  getUpdatedModel: function () {
    this.updateModelWithInput()
    return this.model
  },

  updateModelWithInput: function () {
    this.model.get('accountManagementFormModel').userPreferences.dataAnalyticsOk = this.ui.dataAnalyticsOk.is(':checked')
    this.model.get('accountManagementFormModel').userPreferences.receiveEmailsOk = this.ui.receiveEmailsOk.is(':checked')
  }

})
