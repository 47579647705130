const permanentFailureResponseCodes = ['401', '403']

function isUsingOAuth () {
  return !!myApp.ent.config.getOAuthLoginEndpoint()
}

function invalidate () {
  return myApp.vent.trigger('authentication:remove', true)
}

function validate (token) {
  return new Promise(function (resolve, reject) {
    const handleValidationResponse = function (thirdPartyToken, response) {
      if (response && response.accessToken) {
        myApp.ent.user.set(response)
        myApp.vent.trigger('authentication:success', response, false)
        myApp.utils.token.setAttemptedThirdPartyToken(thirdPartyToken)
        return resolve(true)
      }
      if (token && permanentFailureResponseCodes.indexOf(response.code) > -1) {
        myApp.utils.token.setAttemptedThirdPartyToken(thirdPartyToken)
      }
      const error = 'unable to parse token from response'
      console.error(error, response)
      return reject(new Error(error))
    }

    if (token) {
      myApp.utils.ajax.postDirect({
        url: myApp.actions.oAuthToken(),
        data: {
          token: token
        },
        method: 'POST',
        successHandler: handleValidationResponse.bind(this, token)
      }, true)
    } else {
      return reject(new Error('no token to validate'))
    }
  })
}

function parse (skipTokenCheck) {
  return new Promise(function (resolve, reject) {
    if (skipTokenCheck) {
      return resolve(true)
    }

    function doValidate (thirdPartyToken) {
      return validate(thirdPartyToken)
        .then(function () {
          resolve(true)
        })
        .catch(function () {
          resolve(false)
        })
    }

    // if we're relying on OAuth, check that existing token is still there
    if (isUsingOAuth()) {
      const isUserAuthenticated = myApp.ent.user.isLoggedIn()
      const thirdPartyToken = myApp.utils.token.getThirdPartyToken()
      const bidlogixToken = myApp.utils.token.getToken()
      if (thirdPartyToken) {
        const lastKnownThirdPartyToken = myApp.utils.token.getAttemptedThirdPartyToken()
        if (!isUserAuthenticated) {
          if (thirdPartyToken !== lastKnownThirdPartyToken) {
            return doValidate(thirdPartyToken)
          } else {
            console.warn('Skipping login attempt for token, as previously failed')
          }
        } else {
          // if there is a token mismatch, revalidate token
          if (lastKnownThirdPartyToken && thirdPartyToken !== lastKnownThirdPartyToken) {
            // display revalidation notification
            myApp.vent.trigger('ui:notification', { text: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LOGIN_REVALIDATION'), level: 'warning' })
            return doValidate(thirdPartyToken)
          } else if (!lastKnownThirdPartyToken) {
            return doValidate(thirdPartyToken)
          }
        }
      } else if (isUserAuthenticated && bidlogixToken) {
        return resolve(invalidate())
      }
      return resolve(false)
    }
  })
}

PageApp.OAuthUtils = function () {
  return {
    isUsingOAuth: isUsingOAuth,
    parse: parse
  }
}
