PageApp.Views.ItemImagesView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.itemImages,
  serializeData: function () {
    var numbImages = _.size(this.model.get('images'))

    var dims = { width: 150, height: 112 }
    dims.operation = myApp.ent.config.get('itemImageTransformOperation')

    var content = {
      lbDims: {}, // No width and height so full size will be retrieved from cloudinary
      dims: dims,
      title: this.model.get('title'),
      hasImages: (numbImages > 0),
      images: this.model.get('images'),
      lotNumber: this.model.get('lotNumber'),
      numbImages: numbImages

    }
    return content
  },
  onRender: function () {
    this.prettyphoto()
  },
  prettyphoto: function () {
    /* Pretty Photo */
    // WARNING Do NOT change youtube_link since it is used
    // in the aa_listing.description field
    // to enable videos of Lots to loaded in PrettyPhoto
    var prettyPhotoOptions = {
      show_title: false,
      social_tools: false,
      allow_resize: true,
      deeplinking: false,
      overlay_gallery: false
    }
    this.$el.find('.youtube_link').prettyPhoto(prettyPhotoOptions)
    this.$el.find("a[rel^='prettyPhoto']").prettyPhoto(prettyPhotoOptions)
  }
})
