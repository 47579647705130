window.apiConfiguration = {
  'auctionCalendar': 1,
  'auctionDetails': 1,
  'cardVerificationAjax': 1,
  'cardVerificationSkipAjax': 1,
  'changePassword': 1,
  'confirmEmail': 1,
  'content': 1,
  'createAccount': 1,
  'createAccountValidation': 1,
  'forgottenPassword': 1,
  'home': 1,
  'itemDetails': 1,
  'marketplace': 1,
  'models': 1,
  'myBids': 1,
  'mySettings': 1,
  'prevNextItemDetails': 1,
  'reference': 1,
  'registerForAuction': 1,
  'relatedItems': 1,
  'search': 1,
  'ssologin': 1,
  'timed/autobid': 1,
  'timed/bid': 1,
  'timed/buyNow': 1,
  'timed/contactSeller': 1,
  'timed/items': 1,
  'timed/makeOffer': 1,
  'timed/multiBuy': 1,
  'timed/mystate': 1,
  'timed/mystates': 1,
  'timed/tender': 1,
  'timed/tenderPct': 1,
  'timed/transfer': 1,
  'timed/watching': 1,
  'token': 1,
  'typeahead/item': 1,
  'webcast2': 1,
  'webcast2/item': 1,
  'webcast2/longPoll': 1,
  'webcast2/makeChoice': 1,
  'webcast2/mouseOverBid': 1,
  'webcast2/placeBid': 1,
  'webcast2/placeMaxBid': 1,
  'webcast2/sellingItemsRemainder': 1,
  'webcast2/tokToken': 1
}
