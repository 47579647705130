PageApp.Ent.Status = Backbone.Model.extend({
  setMyBidsFilterSetLabel: function (filterName) {
    var filterId = myApp.ent.user.getPreference('filterId')
    var filter = _.findWhere(this.get('myBidsSearchTypes'), { name: filterName })
    filter.label = myApp.reqres.request('i16:getString', 'MyBidsSearchTypeEnum_' + filter.name)
    filter.chevrons = 'fa-angle-double-right'
    filter.btnClazz = 'default'
    filter.clazz = ''
    if ((filterId && filterId === filter.id) ||
      (!filterId && filterName === 'ALL')) {
      filter.clazz = 'active'
      filter.btnClazz = 'default'
      filter.chevrons = 'fa-angle-double-down'
    }
    return filter
  },
  getMyBidsFilterSet: function () {
    var searchTypeId = myApp.ent.user.getPreference('searchTypeId')
    if (!searchTypeId) {
      return
    }
    var filterSet = []
    var searchType = this.getMyBidsSearchType(searchTypeId)
    filterSet.push(this.setMyBidsFilterSetLabel('ALL'))
    if (searchType.name === 'LIVE_BIDDING') {
      filterSet.push(this.setMyBidsFilterSetLabel('WINNING'))
      filterSet.push(this.setMyBidsFilterSetLabel('LOSING'))
    } else if (searchType.name === 'CLOSED_BIDDING') {
      filterSet.push(this.setMyBidsFilterSetLabel('WON'))
      filterSet.push(this.setMyBidsFilterSetLabel('LOST'))
    } else if (searchType.name === 'LIVE_OFFERS') {
      filterSet.push(this.setMyBidsFilterSetLabel('PURCHASED'))
      filterSet.push(this.setMyBidsFilterSetLabel('REJECTED'))
      filterSet.push(this.setMyBidsFilterSetLabel('PENDING'))
    } else if (searchType.name === 'LIVE_WATCHING') {
      filterSet.push(this.setMyBidsFilterSetLabel('WINNING'))
      filterSet.push(this.setMyBidsFilterSetLabel('LOSING'))
      filterSet.push(this.setMyBidsFilterSetLabel('PENDING'))
    }
    return filterSet
  },
  getMyBidsSearchType: function (searchTypeId) {
    if (!searchTypeId) {
      searchTypeId = 1
    }
    var searchType = _.findWhere(this.get('myBidsSearchTypes'), { id: searchTypeId })
    if (!searchType) {
      searchType = _.findWhere(this.get('myBidsSearchTypes'), { name: 'LIVE_BIDDING' })
    }
    return searchType
  },
  getMyBidsCurrentFilter: function () {
    var filterId = myApp.ent.user.getPreference('filterId')
    if (!filterId) return
    return _.findWhere(this.get('myBidsSearchTypes'), { id: filterId })
  },
  getRabbitResponseById: function (responseCode) {
    var code = _.find(this.attributes.rabbitResponseCodes, function (code) {
      if (code.id === responseCode) {
        return code
      }
    })
    return code
  },
  getRabbitResponseByName: function (responseName) {
    var code = _.find(this.attributes.rabbitResponseCodes, function (code) {
      if (code.name === responseName) {
        return code
      }
    })
    return code
  },
  isRabbitQuickie: function (responseCode) {
    var code = this.getRabbitResponseById(responseCode)
    return code.quickResponse
  },
  isRabbitSecure: function (responseCode) {
    var code = this.getRabbitResponseById(responseCode)
    return code.secure && (code.name.indexOf('PUBLIC') === 0)
  },

  isUserWinningOrWon: function (userState) {
    var userBiddingStatuses = this.attributes.userBiddingStatuses
    var userStatus = myApp.ent.inPlay.get('userBiddingData').userBiddingStatus
    return (userStatus === userBiddingStatuses.WINNING) || (userStatus === userBiddingStatuses.WON)
  },

  w2StateInPlayEndedWaitingChoice: function (currentW2State) {
    return (currentW2State === this.attributes.webcastStatesByName.INPLAY_ENDED_WAITING_CHOICE)
  },

  w2StateInPlayPaused: function (currentW2State) {
    return (currentW2State === this.attributes.webcastStatesByName.INPLAY_PAUSED)
  },

  w2StateInPlayBiddable: function (currentW2State) {
    var webcastStatuses = this.attributes.webcastStatesByName
    return (currentW2State === webcastStatuses.INPLAY_SINGLE) || (currentW2State === webcastStatuses.INPLAY_GROUP)
  },

  w2StateInPlayEndedAny: function (currentW2State) {
    var webcastStatuses = this.attributes.webcastStatesByName
    return (currentW2State === webcastStatuses.INPLAY_ENDED) || (currentW2State === webcastStatuses.INPLAY_ENDED_WAITING_CHOICE) ||
      (currentW2State === webcastStatuses.INPLAY_ENDED_CHOSEN) || (currentW2State === webcastStatuses.INPLAY_ENDED_CONFIRMED)
  }

})
