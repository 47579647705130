PageApp.Controllers.AccountRouter = function () {
  function forwardToAccountCreationSuccess (response) {
    var contextPath = myApp.ent.config.get('clientId')
    myApp.utils.misc.sendGoogleAnalytics('CreateAccount', 'success', '', contextPath + '/createAccountSuccess')
    myApp.mainRegion.show(new PageApp.Views.CreateAccountSuccessView({ model: new PageApp.Ent.BasicEntity(response) }))
  }
  function initEvents () {
    myApp.vent.on('account:activate:success', function (response) {
      var model = new PageApp.Ent.BasicEntity(response)
      model.set('JspPublicCodes_JSP_ACCOUNT_NOT_RECOGNISED', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ACCOUNT_NOT_RECOGNISED'))
      model.set('JspPublicCodes_JSP_ACCOUNT_NOT_RECOGNISED_MESSAGE', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ACCOUNT_NOT_RECOGNISED_MESSAGE'))
      myApp.mainRegion.show(new PageApp.Views.CreateAccountActivatedView({ model: model }))
    }, this)
    myApp.vent.on('account:forgotten:success', function (response) {
      var model = new PageApp.Ent.ForgottenPassword(response)
      myApp.mainRegion.show(new PageApp.Views.ForgottenPasswordSuccessView({ model: model }))
    }, this)
    myApp.vent.on('account:forgotten:display', function (response) {
      var model = new PageApp.Ent.ForgottenPassword(response)
      myApp.mainRegion.show(new PageApp.Views.ForgottenPasswordView({ model: model }))
    }, this)
    myApp.vent.on('account:change:password:display', function (response) {
      var model = new PageApp.Ent.ChangePassword(response)
      myApp.mainRegion.show(new PageApp.Views.ChangePasswordView({ model: model }))
    }, this)
    myApp.vent.on('account:change:password:failure', function (response) {
      var model = new PageApp.Ent.ChangePassword(response)
      myApp.mainRegion.show(new PageApp.Views.ChangePasswordFailureView({ model: model }))
    }, this)
    myApp.vent.on('account:change:password:success', function (response) {
      var model = new PageApp.Ent.ChangePassword(response)
      myApp.mainRegion.show(new PageApp.Views.ChangePasswordSuccessView({ model: model }))
    }, this)
    myApp.vent.on('account:create:display', function (models) {
      var model = new PageApp.Ent.BasicEntity(models)
      myApp.mainRegion.show(new PageApp.Views.CreateAccountLayout({ model: model }))
    }, this)
    myApp.vent.on('account:mysettings:display', function (models) {
      var model = new PageApp.Ent.BasicEntity(models)
      myApp.mainRegion.show(new PageApp.Views.MySettingsLayoutView({ model: model }))
    }, this)
    myApp.vent.on('account:mysettings:success', function (response) {
      myApp.refreshModels({ callback: _.bind(function (models) {
        var contextPath = myApp.ent.config.get('clientId')
        myApp.utils.misc.sendGoogleAnalytics('MySettings', 'success', '', contextPath + '/mySettingsSuccess')
        myApp.mainRegion.show(new PageApp.Views.MySettingsSuccessView({ model: new PageApp.Ent.BasicEntity(response) }))
      }, this) })
    }, this)
    myApp.vent.on('account:create:success', function (response) {
      if (myApp.ent.webApp.get('cardVerificationSwitchedOn')) {
        var createAccountCardVerificationLayout = new PageApp.Views.CreateAccountCardVerificationLayoutView({ model: new PageApp.Ent.CardVerification() })
        // @TECHDEBT - myRegion is undefined
        myRegion.show(createAccountCardVerificationLayout) // eslint-disable-line no-undef
        createAccountCardVerificationLayout.creditCardDetailsRegion.show(new PageApp.Views.CreditCardDetailsView({ model: new PageApp.Ent.CreditCardDetails(), referenceData: this.models.referenceData }))
        createAccountCardVerificationLayout.billingAddressDetailsRegion.show(new PageApp.Views.BillingAddressDetailsView({ model: new PageApp.Ent.BillingAddress(), referenceData: this.models.referenceData }))
      } else {
        forwardToAccountCreationSuccess(response)
      }
    }, this)
  }
  return {
    initialize: function () {
      initEvents()
    }
  }
}
