PageApp.Views.BuyNowView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.buyNow,
  templateConfirm: hbs.templates.buyNowConfirm,
  templateWon: hbs.templates.buyNowWon,
  templateLost: hbs.templates.buyNowLost,
  getTemplate: function () {
    var screen = this.model.get('screen')
    if (!screen || screen === 1) {
      return this.template
    } else if (this.model.get('screen') === 2) {
      return this.templateConfirm
    } else {
      if (this.model.get('won')) {
        return this.templateWon
      } else {
        return this.templateLost
      }
    }
  },
  modelEvents: {
    'change': 'render'
  },
  events: {
    'click .x-continue': 'handleContinue',
    'click .x-submit': 'handleBuyNow'
  },
  serializeData: function () {
    var screen = this.model.get('screen')
    var content
    if (!screen || screen === 1) {
      content = {
        JSTextCodes_PROCEED: myApp.reqres.request('i16:getString', 'JSTextCodes_PROCEED'),
        JSTextCodes_CLOSE: myApp.reqres.request('i16:getString', 'JSTextCodes_CLOSE'),
        PageCodes_PAGE_BUY_NOW_HELP_TITLE: myApp.reqres.request('i16:getString', 'PageCodes_PAGE_BUY_NOW_HELP_TITLE'),
        PageCodes_PAGE_BUY_NOW_HELP: myApp.reqres.request('i16:getString', 'PageCodes_PAGE_BUY_NOW_HELP'),
        JspPublicCodes_PURCHASE_DIALOG_BUYERS_PREMIUM_AT: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_BUYERS_PREMIUM_AT'),
        JspPublicCodes_PURCHASE_DIALOG_PRICE: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_PRICE')
      }
    } else if (screen === 2) {
      content = {
        currencySymbol: this.model.attributes.currencySymbol,
        buyersPremium: this.model.attributes.buyersPremium,
        title: this.model.attributes.title,
        numberOrRef: this.model.attributes.numberOrRef,
        minimumOfferOrBuyNowPrice: this.model.attributes.minimumOfferOrBuyNowPrice,
        JspPublicCodes_PURCHASE_DIALOG_PLEASE_PROVIDE_FINAL_CONFIRMATION: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_PLEASE_PROVIDE_FINAL_CONFIRMATION'),
        JspPublicCodes_PURCHASE_DIALOG_FINAL_CONFIRMATION_OF_BUY_NOW: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_FINAL_CONFIRMATION_OF_BUY_NOW'),
        JspPublicCodes_PURCHASE_DIALOG_FINAL_CONFIRMATION: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_FINAL_CONFIRMATION'),
        JSTextCodes_SUBMIT: myApp.reqres.request('i16:getString', 'JSTextCodes_SUBMIT'),
        JSTextCodes_CANCEL: myApp.reqres.request('i16:getString', 'JSTextCodes_CANCEL')
      }
    } else {
      if (this.model.get('won')) {
        content = {
          JspPublicCodes_PURCHASE_DIALOG_PURCHASE_SUCCESSFUL: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_PURCHASE_SUCCESSFUL'),
          JspPublicCodes_PURCHASE_DIALOG_CONGRATULATIONS: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_CONGRATULATIONS'),
          JSTextCodes_CLOSE: myApp.reqres.request('i16:getString', 'JSTextCodes_CLOSE')
        }
      } else {
        content = {
          JspPublicCodes_PURCHASE_DIALOG_BUY_NOW: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_BUY_NOW'),
          JSTextCodes_CLOSE: myApp.reqres.request('i16:getString', 'JSTextCodes_CLOSE')
        }
      }
    }
    content = _.extend(content, this.model.attributes)
    return content
  },
  onRender: function () {
  },
  handleContinue: function (e) {
    e.preventDefault()
    this.model.set('screen', 2)
  },
  handleBuyNow: function (e) {
    e.preventDefault()
    var bean = {
      '@class': 'com.bidlogix.scaffold.models.bidding.BuyNowRequestModel',
      itemId: this.model.attributes.id,
      registrantId: this.model.attributes.registrantId,
      amount: this.model.attributes.minimumOfferOrBuyNowPrice,
      userId: myApp.ent.user.get('id')
    }
    myApp.utils.ajax.postBean(bean, myApp.utils.ajax.getApiEndpoint('timed/buyNow'), _.bind(function (response) {
      if (response.models.ErrorModel) {
        myApp.ent.user.checkLogout(response)
        myApp.vent.trigger('ui:notification', { text: response.models.ErrorModel.message, level: 'warning' })
      } else {
        var itemModel = myApp.request('reqres:timed:set:timedBiddingInfo', { models: response.models, rabbitCode: response.actionCode })
        myApp.ent.purchaseEntity.setModelForBuyNow(itemModel.attributes, 3)
      }
      myApp.vent.trigger('layouthacks:heights')
    }, this))
    return false
  }
})
