PageApp.Ent.LayoutHacks = Backbone.Model.extend({
  scrollbarWidth: 16,
  getDimensionsHomeCal: function () {
    var viewportWidth = $(window).width()
    // Adjust the viewportWidth to make it a consistent value whether or not the vertical scrollbar is currently displayed.
    var hasScrollbar = $(window).height() < $(document).height()
    if (hasScrollbar) {
      viewportWidth += this.scrollbarWidth
    }

    if (viewportWidth < 600) {
      return { width: 300, height: 190 }
    } else if (viewportWidth < 700) {
      return { width: 270, height: 190 }
    } else if (viewportWidth < 1000) {
      return { width: 320, height: 210 }
    } else if (viewportWidth < 1200) {
      return { width: 280, height: 190 }
    } else {
      return { width: 320, height: 210 }
    }
  }
})
