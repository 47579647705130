PageApp.Views.MultiOfferView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.multiOffer,
  events: {
    'click .x-multi-offer': 'handleMultiOffer',
    'click .x-collection': 'handleChangeCollection',
    'change .x-shipping-address': 'handleChangeShippingAddress',
    'keyup .x-add-commas': 'handleMultiOfferKeyup'
  },
  modelEvents: {
    'change': 'render'
  },
  serializeData: function () {
    var label1 = myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_ID_LIKE_TO_OFFER')
    var label2 = 'for'
    var error1Class, error2Class
    if (this.model.get('error1')) {
      label1 = myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_PLEASE_ENTER_AMOUNT')
      error1Class = 'has-error'
    }
    if (this.model.get('error2_1')) {
      label2 = myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_PLEASE_ENTER_AMOUNT')
      error2Class = 'has-error'
    } else if (this.model.get('error2_2')) {
      label2 = 'Too many items!'
      error2Class = 'has-error'
    }

    var collectionCollect = 'checked'
    var collectionDeliver = ''
    var showShipping = false
    if (this.model.get('collectionType') === 'deliver') {
      collectionCollect = ''
      collectionDeliver = 'checked'
      showShipping = true
    }
    var useAddress1 = 'selected'
    var useAddress2 = ''
    if (this.model.get('whichAddress') === 1) {
      useAddress1 = ''
      useAddress2 = 'selected'
    }

    var content = {
      label1: label1,
      label2: label2,
      error1Class: error1Class,
      error2Class: error2Class,
      numberOfUnitsRemaining: this.model.get('numberOfUnitsRemaining'),
      resultMessage: this.model.get('resultMessage'),
      helpTitle: myApp.reqres.request('i16:getString', 'PageCodes_PAGE_BULK_BUY_HELP_TITLE'),
      helpContent: myApp.reqres.request('i16:getString', 'PageCodes_PAGE_BULK_BUY_HELP'),
      showShipping: showShipping,
      collectionCollect: collectionCollect,
      collectionDeliver: collectionDeliver,
      useAddress1: useAddress1,
      useAddress2: useAddress2,

      JSTextCodes_PROCEED: myApp.reqres.request('i16:getString', 'JSTextCodes_PROCEED'),
      JSTextCodes_CLOSE: myApp.reqres.request('i16:getString', 'JSTextCodes_CLOSE'),

      JspPublicCodes_PURCHASE_DIALOG_MAKE_OFFER: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_MAKE_OFFER'),
      JspPublicCodes_PURCHASE_DIALOG_TITLE: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_TITLE'),
      JspPublicCodes_PURCHASE_DIALOG_BUYERS_PREMIUM_AT: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_BUYERS_PREMIUM_AT'),
      JspPublicCodes_PURCHASE_DIALOG_PRICE: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_PRICE')
    }
    content = _.extend(content, this.model.attributes)
    return content
  },
  onRender: function () {
    if (this.model.get('collectionType') === 'deliver') {
      myApp.vent.trigger('address:display', { region: this.$el.find('#addressRegion'), model: { allowEdit: true, whichAddress: this.model.get('whichAddress') } })
    }
    myApp.vent.on('address:render', function (config) {
      if (config.editing) {
        this.$el.find('.x-multi-offer').addClass('disabled')
      } else {
        this.$el.find('.x-multi-offer').removeClass('disabled')
      }
    }, this)
    if (this.model.get('offerAmount') && this.model.get('offerAmount') > 0) {
      var $input = this.$el.find('#offerAmount')
      $input.val(myApp.utils.types.toFormattedNumber($input.val(), myApp.ent.user.attributes.thousandsSeparator))
    }
  },
  handleChangeCollection: function (e) {
    e.preventDefault()
    var collectionType = $(e.currentTarget).val()
    var showShipping = (collectionType !== 'collect')
    var offerAmount = this.$el.find('#offerAmount').val()
    var numberRequired = this.$el.find('#numberRequired').val()
    this.model.set({ 'offerAmount': offerAmount, 'numberRequired': numberRequired, 'showShipping': showShipping, 'collectionType': collectionType })
  },
  handleChangeShippingAddress: function (e) {
    e.preventDefault()
    var whichAddress = myApp.utils.types.toNumber($(e.currentTarget).val())
    var offerAmount = this.$el.find('#offerAmount').val()
    var numberRequired = this.$el.find('#numberRequired').val()
    this.model.set({ 'offerAmount': offerAmount, 'numberRequired': numberRequired, 'whichAddress': whichAddress })
  },
  isInValid: function () {
    var error1
    var error2 = {
      1: null,
      2: null
    }
    var offerAmount = this.$el.find('#offerAmount').val()
    var numberRequired = this.$el.find('#numberRequired').val()
    try {
      offerAmount = myApp.utils.types.toNumber(offerAmount)
      if (!offerAmount || offerAmount.length === 0) {
        error1 = true
      }
    } catch (err) {
      error1 = true
    }
    try {
      numberRequired = myApp.utils.types.toNumber(numberRequired)
      if (!numberRequired || numberRequired.length === 0) {
        error2[1] = true
      } else if (numberRequired > this.model.get('numberOfUnitsRemaining')) {
        error2[2] = true
      }
    } catch (err) {
      error2[1] = true
    }
    var silent = true
    if (error1 || error2[1] || error2[2]) {
      silent = false
    }
    this.model.set({
      'offerAmount': offerAmount,
      'numberRequired': numberRequired,
      'error1': error1,
      'error2_1': error2[1],
      'error2_2': error2[2]
    }, { silent: silent })
    return error1 || error2[1] || error2[2]
  },
  handleMultiOfferKeyup: function (e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      this.handleMultiOffer()
      return false
    }
  },
  handleMultiOffer: function (e) {
    if (e) e.preventDefault()
    if (this.isInValid()) {
      myApp.vent.trigger('ui:notification', { text: 'Please correct the errors and try again..', level: 'warning' })
      return
    }
    var shippingType = 2
    if (this.model.get('collectionType') === 'deliver') {
      shippingType = this.model.get('whichAddress')
    }
    var bean = {
      '@class': 'com.bidlogix.scaffold.models.bidding.MultiBuyRequestModel',
      itemId: this.model.attributes.id,
      registrantId: this.model.attributes.registrantId,
      amount: this.model.get('offerAmount'),
      userId: myApp.ent.user.get('id'),
      numberOfUnitsWanted: this.model.get('numberRequired'),
      shippingType: shippingType
    }

    myApp.utils.ajax.postBean(bean, myApp.utils.ajax.getApiEndpoint('timed/multiBuy'), _.bind(function (response) {
      if (response.models.BiddingItemModel) {
        var itemModel = myApp.request('reqres:timed:set:timedBiddingInfo', { models: response.models, rabbitCode: response.actionCode })
        myApp.ent.purchaseEntity.setModelForMakeOffer(itemModel.attributes, false, response.models.ErrorModel)
        if (response.models.ErrorModel) {
          myApp.vent.trigger('ui:notification', { text: response.models.ErrorModel.message, level: 'warning' })
        }
      } else if (response.models.ErrorModel) {
        myApp.ent.user.checkLogout(response)
        myApp.vent.trigger('ui:notification', { text: response.models.ErrorModel.message, level: 'warning' })
        this.model.set({ 'hasResult': true, 'resultClass': 'text-danger', 'resultMessage': response.models.ErrorModel.message })
      }
      myApp.vent.trigger('layouthacks:heights')
    }, this))
    return false
  }
})
