PageApp.Controllers.TimedRouter = function (config) {
  var myRegion
  var myItemCollection = new PageApp.Ent.TimedItems()
  var existingPoll = null
  var isPolling = false
  var isHealthChecking = false
  var hasConnection = true
  var pollCount = 0
  var pollRetryBase = 1000
  var pollRetryTime = 1000
  var pollRetryMultiplier = 2
  var pollRetryMax = 20000
  function handleLongPollFailure (timedResponse) {
    if (console.log) {
      console.log('Error polling server. Page reload may be required: ' + (timedResponse ? timedResponse.message : ''))
    }
    if (hasConnection) {
      setTimeout(function () {
        myApp.vent.trigger('timed:poll')
      }, pollRetryTime)
      console.warn('Poll Failed: Retrying in ' + (pollRetryTime / 1000) + ' seconds')
      pollRetryTime = Math.min(pollRetryTime * pollRetryMultiplier, pollRetryMax)
    } else {
      isPolling = false
      existingPoll = null
    }
  }
  function handleLongPollSuccess (timedResponse) {
    // validate body of timedResponse, ensuring it's the correct class and has not errored.
    // otherwise use failure process instead, treating as an error
    if (timedResponse && (timedResponse['@class'] !== '.TimedResponse' || !timedResponse.worked || (timedResponse.models && timedResponse.models.ErrorModel))) {
      return handleLongPollFailure(timedResponse)
    }
    try {
      if (pollRetryTime !== pollRetryBase) {
        console.log('Poll Success: Retry time reset to ' + (pollRetryBase / 1000) + ' seconds')
        // reset pollRetryTime, as any ramping up of this is now defunct
        pollRetryTime = pollRetryBase
      }
      var biddingItemModel
      if (timedResponse.models.BiddingItemModel || timedResponse.models.TimedListModel) {
        var biddingItemModels = []

        if (timedResponse.models.BiddingItemModel) {
          biddingItemModels.push(timedResponse.models.BiddingItemModel)
        } else {
          var models = timedResponse.models.TimedListModel.biddingItems
          for (var i = 0; i < _.size(models); i++) {
            biddingItemModels.push(models[i])
          }
        }

        for (var j = 0; j < _.size(biddingItemModels); j++) {
          var responseModel = biddingItemModels[j]

          biddingItemModel = myItemCollection.get(responseModel)

          if (responseModel.timedBiddingInfo && responseModel.timedUserInfo) {
            biddingItemModel.set({
              'timedBiddingInfo': responseModel.timedBiddingInfo,
              'timedUserInfo': timedResponse.models.TimedUserInfoModel,
              rabbitCode: timedResponse.actionCode
            }, { silent: true })
          } else if (responseModel.timedUserInfo) {
            biddingItemModel.set({ 'timedUserInfo': responseModel.timedUserInfo, rabbitCode: timedResponse.actionCode }, { silent: true })
          } else if (responseModel.timedBiddingInfo) {
            biddingItemModel.set({ 'timedBiddingInfo': responseModel.timedBiddingInfo, rabbitCode: timedResponse.actionCode }, { silent: true })
          }
          biddingItemModel.set('userStatus', myApp.ent.timedHelper.getUserStatus(biddingItemModel.attributes), { silent: true })
          biddingItemModel.set('lastupdate', (new Date()).getTime())
        }
      }

      if (timedResponse.actionCode === myApp.ent.status.getRabbitResponseByName('REGISTRANT_STATUS_CHANGE').id) {
        // REGISTRANT_STATUS_CHANGE - admin approves, suspends etc a registrant..
        var registrant = timedResponse.models.userRegistrationModel
        myItemCollection.each(function (item) {
          if (item.get('registrant').id === registrant.id) {
            item.set({ 'registrant': registrant, rabbitCode: timedResponse.actionCode }, { silent: true })
            item.set('userStatus', myApp.ent.timedHelper.getUserStatus(item.attributes), { silent: true })
            item.set('lastupdate', (new Date()).getTime())
            // Clicking the filters button reloads the original state of the registrant so we need
            // to update the LandingPage's version of the item
            var cachedItem = myApp.request('reqres:timed:fetch:item', { itemId: item.id })
            cachedItem.registrant = registrant
          }
        }, this)
      } else if (timedResponse.actionCode === myApp.ent.status.getRabbitResponseByName('ADMIN_WEBCAST_STARTED').id) {
        if (myApp.ent.config.get('page') === 'auctionland') {
          var first
          if (myItemCollection && myItemCollection.models && _.size(myItemCollection.models) > 0) {
            first = myItemCollection.models[0]
          }
          if (first && first.get('auctionId') === timedResponse.models.auctionModel.auctionId) {
            myApp.vent.trigger('ui:notification', { text: 'Webcast Auction Starts Now!', level: 'success' })
            window.location.reload()
          }
        }
      } else if (timedResponse.actionCode === myApp.ent.status.getRabbitResponseByName('ADMIN_SETTINGS_CHANGE').id) {
        myApp.vent.trigger('timed:refresh')
      } else if (timedResponse.actionCode === myApp.ent.status.getRabbitResponseByName('PUBLIC_BUY_NOW').id ||
        timedResponse.actionCode === myApp.ent.status.getRabbitResponseByName('PUBLIC_MAX_BID').id ||
        timedResponse.actionCode === myApp.ent.status.getRabbitResponseByName('ADMIN_ACTION_OFFER_STATE_CHANGE').id ||
        timedResponse.actionCode === myApp.ent.status.getRabbitResponseByName('PUBLIC_MAKE_OFFER').id ||
        timedResponse.actionCode === myApp.ent.status.getRabbitResponseByName('PUBLIC_MULTI_BUY').id) {
        // PUBLIC_BUY_NOW - a user bought this item - update user buy now dialog if open
        myApp.vent.trigger('timed:refresh:item', { id: timedResponse.itemId, actionCode: timedResponse.actionCode })
      }

      var mybidsFilterSet = myApp.ent.status.getMyBidsFilterSet()
      if (mybidsFilterSet && myApp.ent.config.get('page') === 'myBidsLand') {
        // If we're on mybids and filtering results we need to render all the children of the collection
        // E.g. say I am filtering on 'losing' items and the last event was a cancel bid that
        // changed my state to winning then I need to do the full re-render to for the item to be removed
        // from the view.
        myApp.vent.trigger('timed:display')
      } else {
        myApp.vent.trigger('timed:poll')
      }
    } catch (err) {
      if (console.log) {
        console.log(err)
      }
      myApp.vent.trigger('ui:notification', { text: err, level: 'danger' })
      myApp.vent.trigger('timed:poll')
    }
  }

  function initCommands () {
    myApp.reqres.setHandler('reqres:timed:fetch:count', function (config) {
      return _.size(myItemCollection)
    })
    myApp.reqres.setHandler('reqres:timed:fetch:item', function (config) {
      return myItemCollection.get({ id: config.itemId })
    })
    myApp.reqres.setHandler('reqres:timed:set:timedBiddingInfo', function (config) {
      var model = myItemCollection.get(config.models.BiddingItemModel)
      if (config.models.TimedUserInfoModel) {
        model.set({
          'timedBiddingInfo': config.models.BiddingItemModel.timedBiddingInfo,
          'timedUserInfo': config.models.TimedUserInfoModel,
          rabbitCode: config.rabbitCode
        })
      } else {
        model.set({ 'timedBiddingInfo': config.models.BiddingItemModel.timedBiddingInfo, rabbitCode: config.rabbitCode })
      }
      return model
    })
  }
  function initEvents () {
    myApp.ent.timedHelper = new PageApp.Ent.Timed2PrimaryHelper()
    myApp.ent.purchaseEntity = new PageApp.Ent.PurchaseEntity()
    myApp.vent.on('user:logged:out', function (models) {
      myApp.vent.trigger('timed:display')
    }, this)
    myApp.vent.on('timed:refresh:item', function (config) {
      var biddingItemModel = myItemCollection.get(config.id)
      myApp.utils.ajax.get(null, myApp.utils.ajax.getApiEndpoint('timed/mystate') + '/' + config.id, _.bind(function (response) {
        var registrant = biddingItemModel.get('registrant')
        response.models.BiddingItemModel.timedUserInfo = response.models.TimedUserInfoModel
        response.models.BiddingItemModel.registrant = registrant
        biddingItemModel.set(response.models.BiddingItemModel)
        biddingItemModel.set('lastupdate', (new Date()).getTime())

        var refreshDialog = config.actionCode === myApp.ent.status.getRabbitResponseByName('ADMIN_ACTION_OFFER_STATE_CHANGE').id
        var refreshBuyNowDialog = refreshDialog || config.actionCode === myApp.ent.status.getRabbitResponseByName('PUBLIC_BUY_NOW').id
        if (refreshBuyNowDialog) {
          myApp.ent.purchaseEntity.setModelForBuyNow(biddingItemModel.attributes, 3)
        }
        var refreshMakeOfferDialog = refreshDialog || config.actionCode === myApp.ent.status.getRabbitResponseByName('PUBLIC_MAKE_OFFER').id
        refreshMakeOfferDialog = refreshMakeOfferDialog || config.actionCode === myApp.ent.status.getRabbitResponseByName('PUBLIC_MULTI_BUY').id
        if (refreshMakeOfferDialog) {
          myApp.ent.purchaseEntity.setModelForMakeOffer(biddingItemModel.attributes, true, false)
        }

        var refreshTransferDialog = refreshDialog || config.actionCode === myApp.ent.status.getRabbitResponseByName('PUBLIC_TRANSFER').id
        if (refreshTransferDialog) {
          myApp.ent.purchaseEntity.setModelForTransfer(biddingItemModel.attributes, 3)
        }

        var refreshTenderDialog = refreshDialog || config.actionCode === myApp.ent.status.getRabbitResponseByName('PUBLIC_TENDER').id
        if (refreshTenderDialog) {
          myApp.ent.purchaseEntity.setModelForTender(biddingItemModel.attributes, true)
        }
      }, this))
    }, this)
    myApp.vent.on('timed:refresh', function () {
      var itemIds = myItemCollection.getItemIds()
      myApp.utils.ajax.get(null, myApp.utils.ajax.getApiEndpoint('timed/mystates') + '/' + itemIds.join(','), _.bind(function (response) {
        if (response.models.TimedListModel && response.models.TimedListModel.biddingItems) {
          myItemCollection = new PageApp.Ent.TimedItems(response.models.TimedListModel.biddingItems)
        } else {
          myItemCollection = new PageApp.Ent.TimedItems()
        }
        myApp.vent.trigger('timed:display')
      }, this))
    }, this)
    myApp.vent.on('timed:display', function (config) {
      var layout = myApp.ent.user.getPreference('layoutForBidding')
      myApp.ent.user.setPreference('layoutForBidding', layout)
      // ie. some re-rendering relies on the cached timed bidding states being up to date here.
      if (config) {
        if (config.region) {
          myRegion = config.region
        }
        if (config.model) {
          myItemCollection = new PageApp.Ent.TimedItems(config.model.TimedListModel.biddingItems)
        }
      }

      var xcoord = window.pageXOffset || document.documentElement.scrollLeft
      var ycoord = window.pageYOffset || document.documentElement.scrollTop

      if (!hasConnection) {
        var alertModel = new PageApp.Ent.BasicEntity({
          content: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TIMED_OFFLINE_MESSAGE'),
          type: 'warning',
          showLoader: true
        })
        myRegion.show(new PageApp.Views.AlertView({
          model: alertModel
        }))
      } else {
        myRegion.show(new PageApp.Views.CollectionContainerLayout({ model: myItemCollection }))
      }
      window.scroll(xcoord, ycoord)
      // BD-5027 - needs re-thinking.. myApp.vent.trigger('timed:healthCheck', false);
      myApp.vent.trigger('timed:poll')
    }, this)
    myApp.vent.on('timed:touch:item', function (config) {
      var biddingItemModel = myItemCollection.get(config.id)
      if (biddingItemModel) {
        biddingItemModel.set('lastupdate', (new Date()).getTime())
      }
    }, this)
    myApp.vent.on('timed:healthCheck', function (force) {
      if (!isHealthChecking || force) {
        // BD-5027 - needs re-thinking..
        // $.ajax({
        //   url: myApp.ent.config.attributes.contextPath + myApp.actions.ping + '?random-no-cache=' + Math.floor(Date.now() / 1000),
        //   timeout: 5000,  // timeout 5s
        //   success: myApp.vent.trigger.bind(this, 'timed:healthCheck:success'),
        //   error: myApp.vent.trigger.bind(this, 'timed:healthCheck:failure'),
        //   complete: function() {
        //     setTimeout(function() {
        //       myApp.vent.trigger('timed:healthCheck', true)
        //     }, 1000)
        //   }
        // });
        // isHealthChecking = true
      }
    })
    myApp.vent.on('timed:healthCheck:success', function () {
      if (!hasConnection) {
        console.warn('Restored connection')
        hasConnection = true
        myApp.vent.trigger('timed:display')
      }
      // if we've stopped polling due to unhealthy connection and now have a healthy connection, we need to poll again
      if (!isPolling) {
        myApp.vent.trigger('timed:poll')
      }
    })
    myApp.vent.on('timed:healthCheck:failure', function (err) {
      if (hasConnection) {
        console.warn('Lost connection')
        hasConnection = false

        var alertEntity = (err && err.status === 404) ? {
          content: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TIMED_OFFLINE_MESSAGE'),
          type: 'warning',
          showLoader: true
        } : {
          content: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TIMED_ERROR_MESSAGE'),
          type: 'danger',
          showLoader: true
        }

        var alertModel = new PageApp.Ent.BasicEntity(alertEntity)
        myRegion.show(new PageApp.Views.AlertView({
          model: alertModel
        }))
      }
      if (existingPoll && existingPoll.abort) {
        existingPoll.abort()
      }
    })
    myApp.vent.on('timed:poll', function () {
      pollCount = pollCount + 1
      if (pollCount > 1) {
        console.warn('A duplicate long poll was created! There are currently ' + pollCount + ' long polls active')
      }
      var bean = myItemCollection.getLongPollUrl()
      if (bean) {
        isPolling = true
        // create a new longPoll, and record it so we can cancel it if need be
        existingPoll = myApp.utils.ajax.postBean(bean, myApp.utils.ajax.getApiEndpoint('timed/items'), _.bind(function (timedResponse) {
          if (timedResponse && timedResponse.actionCode === myApp.ent.status.getRabbitResponseByName('PUBLIC_TERMINATE_AND_ADD').id) {
            // PUBLIC_TERMINATE_AND_ADD check if need to stop polling (the data has changed and a new long has been issued) or just add new long poll...
            return
          }
          pollCount = pollCount - 1
          if (!timedResponse || timedResponse.failed) {
            handleLongPollFailure(timedResponse)
          } else {
            handleLongPollSuccess(timedResponse)
          }
        }, this))
      }
    }, this)
  }
  return {
    initialize: function (models) {
      initEvents()
      initCommands()
    }
  }
}
