PageApp.Views.MakeOfferView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.makeOffer,
  events: {
    'click .x-make-offer': 'handleMakeOffer',
    'keyup .x-add-commas': 'handleMakeOfferKeyup'
  },
  modelEvents: {
    'change': 'render'
  },
  serializeData: function () {
    var label1 = this.model.get('label1')
    if (!label1) {
      label1 = myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_ID_LIKE_TO_OFFER')
    }
    var error1Class
    if (this.model.get('error1')) {
      label1 = myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_PLEASE_ENTER_AMOUNT')
      error1Class = 'has-error'
    }
    var showNumberOfUnits = !this.model.get('isMultiItem') && this.model.get('numberOfUnits') > 1

    var content = {
      label1: label1,
      error1Class: error1Class,
      helpTitle: myApp.reqres.request('i16:getString', 'PageCodes_PAGE_MAKE_OFFER_HELP_TITLE'),
      helpContent: myApp.reqres.request('i16:getString', 'PageCodes_PAGE_MAKE_OFFER_HELP'),
      showNumberOfUnits: showNumberOfUnits,

      JSTextCodes_PROCEED: myApp.reqres.request('i16:getString', 'JSTextCodes_PROCEED'),
      JSTextCodes_CLOSE: myApp.reqres.request('i16:getString', 'JSTextCodes_CLOSE'),
      JspPublicCodes_PURCHASE_DIALOG_MAKE_OFFER: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_MAKE_OFFER'),
      JspPublicCodes_PURCHASE_DIALOG_TITLE: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_TITLE'),
      JspPublicCodes_PURCHASE_DIALOG_BUYERS_PREMIUM_AT: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_BUYERS_PREMIUM_AT'),
      JspPublicCodes_PURCHASE_DIALOG_PRICE: myApp.reqres.request('i16:getString', 'JspPublicCodes_PURCHASE_DIALOG_PRICE'),
      JspPublicCodes_JSP_BIDDING_NUMBER_OF_UNITS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_NUMBER_OF_UNITS')
    }
    content = _.extend(content, this.model.attributes)
    return content
  },
  handleMakeOfferKeyup: function (e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      this.handleMakeOffer()
      return false
    }
  },
  onRender: function () {
    if (this.model.get('offerAmount') && this.model.get('offerAmount') > 0) {
      var $input = this.$el.find('#offerAmount')
      $input.val(myApp.utils.types.toFormattedNumber($input.val(), myApp.ent.user.attributes.thousandsSeparator))
    }
  },
  isInValid: function () {
    var offerAmount = this.$el.find('#offerAmount').val()
    var error1
    try {
      if (!myApp.utils.types.isWholeNumber(offerAmount, myApp.ent.user.attributes.thousandsSeparator)) {
        error1 = true
      } else {
        offerAmount = myApp.utils.types.toNumber(offerAmount)
        if (!offerAmount || offerAmount.length === 0) {
          error1 = true
        }
      }
    } catch (err) {
      error1 = true
    }
    var silent = true
    if (error1) {
      silent = false
    }
    this.model.set({
      'offerAmount': offerAmount,
      'error1': error1
    }, { silent: silent })
    return error1
  },
  handleMakeOffer: function (e) {
    if (e) e.preventDefault()
    if (this.isInValid()) {
      myApp.vent.trigger('ui:notification', { text: 'Please correct the errors and try again..', level: 'warning' })
      return
    }
    var bean = {
      '@class': 'com.bidlogix.scaffold.models.bidding.BuyNowRequestModel',
      itemId: this.model.attributes.id,
      registrantId: this.model.attributes.registrantId,
      amount: this.model.get('offerAmount'),
      userId: myApp.ent.user.get('id')
    }
    myApp.utils.ajax.postBean(bean, myApp.utils.ajax.getApiEndpoint('timed/makeOffer'), _.bind(function (response) {
      if (response.models.BiddingItemModel) {
        var itemModel = myApp.request('reqres:timed:set:timedBiddingInfo', { models: response.models, rabbitCode: response.actionCode })
        myApp.ent.purchaseEntity.setModelForMakeOffer(itemModel.attributes, false)
        if (response.models.ErrorModel) {
          myApp.vent.trigger('ui:notification', { text: response.models.ErrorModel.message, level: 'warning' })
        }
      } else if (response.models.ErrorModel) {
        myApp.ent.user.checkLogout(response)
        myApp.vent.trigger('ui:notification', { text: response.models.ErrorModel.message, level: 'warning' })
        this.model.set({ 'hasResult': true, 'resultClass': 'text-danger', 'resultMessage': response.models.ErrorModel.message })
      }
      myApp.vent.trigger('layouthacks:heights')
    }, this))
    return false
  }
})
