PageApp.Controllers.RegisterRouter = function (config) {
  var model = new PageApp.Ent.BasicEntity()
  function initCommands () {
  }
  function initEvents () {
    myApp.vent.on('register:display', function (response) {
      if (response.alreadyRegistered) {
        myApp.mainRegion.show(new PageApp.Views.ContentView({ model: new PageApp.Ent.BasicEntity({ 'content': response.alreadyRegisteredText }) }))
      } else {
        model = new PageApp.Ent.BasicEntity(response)
        if (model.title) {
          document.title = model.title
        }
        myApp.mainRegion.show(new PageApp.Views.RegisterView({ model: model }))
      }
    }, this)
    myApp.vent.on('register:success', function (response) {
      myApp.mainRegion.show(new PageApp.Views.RegisterSuccessView({ model: new PageApp.Ent.BasicEntity(response) }))
    }, this)
  }
  function initRoutes (obj) {
  }
  return {
    initialize: function (models) {
      initRoutes(this)
      initEvents()
      initCommands()
    },
    displayItem: function (id) {
    }

  }
}
