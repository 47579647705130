PageApp.Ent.Config = Backbone.Model.extend({
  initialize: function (options) {
    this.set('containerClass', 'container')
    this.set('serverVsClientTimeOffset', new Date().getTime() - this.get('serverTimestamp'))
    this.set('confirmAmountThresholdMultiplier', 20)

    let clientId = options.config.clientId
    if (clientId && clientId.indexOf('/') === -1) {
      clientId = `/${clientId}`
    }

    this.set('apiBase', options.config.apiBase)
    this.set('clientId', clientId)
    this.set('staticBase', options.config.staticBase)
    this.set('googleMapsApiKey', options.config.googleMapsApiKey)
  },
  defaults: {
    containerClass: 'container',
    protocol: 'https'
  },
  getNormalisedTimeMillis: function () {
    return new Date().getTime() - this.get('serverVsClientTimeOffset')
  },
  getReferer: function () {
    if (this.get('referer')) {
      return this.get('referer')
    } else {
      return '#!/home'
    }
  },
  getAdminUrl: function () {
    var apiBase = this.get('apiBase')
    if (apiBase) {
      var apiBasePieces = apiBase.split('/')
      if (apiBasePieces.length > 1) {
        // remove the "/api" portion
        apiBasePieces.pop()
        // replace the contextPath with auction-mgt
        apiBasePieces[apiBasePieces.length - 1] = 'auction-mgt'
        return apiBasePieces.join('/')
      }
    }
    return false
  },
  getMapsAPIKey: function () {
    return this.get('googleMapsApiKey')
  },
  getOAuthLoginEndpoint: function () {
    var options = this.get('options')
    return options && options.oAuth && options.oAuth.loginUrl
  },
  getOAuthLogoutEndpoint: function () {
    var options = this.get('options')
    return options && options.oAuth && options.oAuth.logoutUrl
  },
  getOAuthTokenInformation: function () {
    var options = this.get('options')
    return options && options.oAuth && options.oAuth.tokenName && {
      name: options.oAuth.tokenName,
      isTokenLocalStorage: options.oAuth.isTokenLocalStorage
    }
  }
})

PageApp.Ent.PublicStaticLabels = Backbone.Model.extend({})
