PageApp.Views.CreateAccountLayout = Backbone.Marionette.LayoutView.extend({
  template: hbs.templates.createAccount,
  events: {
    'click #submitButton': 'handleSubmit',
    'keyup :text': 'handleSubmitFromInput',
    'focus .form-control': 'clearErrors',
    'click #receiveEmailsOk': 'clearErrors',
    'click #agreedTsAndCsAndPrivacyPolicy': 'clearErrors'
  },
  regions: {
    usernamePassword: '#usernamePasswordView',
    personalDetails: '#personalDetailsView',
    contactDetails: '#contactDetailsView',
    userPreferences: '#userPreferencesView',
    termsAndConditions: '#termsAndConditionsView'
  },
  modelEvents: {
    'change': 'modelChanged'
  },
  modelChanged: function (e) {
    this.render()
  },
  serializeData: function () {
    var data = {
      JspPublicCodes_JSP_CreateAccount_CreateAccount: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_CreateAccount')
    }
    return data
  },
  onRender: function () {
    this.usernamePassword.show(new PageApp.Views.UsernamePasswordView({ model: this.model }))
    this.contactDetails.show(new PageApp.Views.ContactDetailsView({ model: this.model }))
    this.personalDetails.show(new PageApp.Views.PersonalDetailsView({ model: this.model }))
    this.userPreferences.show(new PageApp.Views.UserPreferencesView({ model: this.model }))
    this.termsAndConditions.show(new PageApp.Views.TermsAndConditionsView({ model: this.model }))
  },
  clearErrors: function (e) {
    var $targetField = $(e.currentTarget)
    myApp.utils.validation.clearFieldError($targetField)
  },
  getUpdatedModel: function () {
    this.updateModelWithInput()
    return this.model
  },
  updateModelWithInput: function () {
    this.model.get('accountManagementFormModel').username = this.usernamePassword.currentView.ui.username.val().trim()
    this.model.get('accountManagementFormModel').email = this.usernamePassword.currentView.ui.email.val().trim()
    this.model.get('accountManagementFormModel').repeatEmail = this.usernamePassword.currentView.ui.repeatEmail.val().trim()
    this.model.get('accountManagementFormModel').password = this.usernamePassword.currentView.ui.password.val().trim()
    this.model.get('accountManagementFormModel').repeatPassword = this.usernamePassword.currentView.ui.repeatPassword.val().trim()
    this.model.get('accountManagementFormModel').agreedTsAndCsAndPrivacyPolicy = this.termsAndConditions.currentView.ui.agreedTsAndCsAndPrivacyPolicy.is(':checked')
    this.contactDetails.currentView.getUpdatedModel()
    this.personalDetails.currentView.getUpdatedModel()
    this.userPreferences.currentView.getUpdatedModel()
  },
  handleSubmitFromInput: function (e) {
    e.preventDefault()
    if (e.keyCode === 13) {
      this.handleSubmit(e)
    }
    return false
  },
  handleSubmit: function (e) {
    e.preventDefault()
    this.updateModelWithInput()
    var baseUrl = encodeURIComponent(window.location.href)
    myApp.utils.ajax.postBean(this.model.attributes.accountManagementFormModel, myApp.utils.ajax.getApiEndpoint('createAccount') + '?baseUrl=' + baseUrl, function (response) {
      var errors = response.errors
      if (typeof errors !== 'undefined' && errors !== null && errors.length !== 0) {
        myApp.utils.validation.renderErrors(new PageApp.Ent.Errors(errors))
      } else if (response.failed) {
        myApp.utils.validation.renderErrors(new PageApp.Ent.Errors(errors))
      } else {
        myApp.vent.trigger('account:create:success', response)
      }
    })
    return false
  },
  handleError: function (model, xhr, options) {
    if (xhr.status === 400) {
      var errors = new PageApp.Ent.Errors(xhr.responseJSON.errors)
      myApp.utils.validation.renderErrors(errors)
      return
    }
    myApp.vent.trigger('error:notifyServerError', _.pick(xhr, 'responseJSON', 'status', 'statusText'))
  }
})
