function escape (s) {
  return s.replace(/([.*+?^${}()|[\]/\\])/g, '\\$1')
}

function getCookie (name) {
  const match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'))
  return match ? match[1] : null
}

PageApp.TokenUtils = function () {
  const tokenName = 'bidJSToken'
  let thirdPartyToken = null
  return {
    getToken: function () {
      return window.localStorage.getItem(tokenName)
    },
    setToken: function (value) {
      window.localStorage.setItem(tokenName)
    },
    deleteToken: function () {
      window.localStorage.removeItem(tokenName)
    },
    getThirdPartyToken: function () {
      var thirdPartyTokenInformation = myApp.ent.config.getOAuthTokenInformation()
      if (thirdPartyTokenInformation.isTokenLocalStorage) {
        return window.localStorage.getItem(thirdPartyTokenInformation.name)
      }
      return getCookie(thirdPartyTokenInformation.name)
    },
    getAttemptedThirdPartyToken: function () {
      return thirdPartyToken
    },
    setAttemptedThirdPartyToken: function (value) {
      thirdPartyToken = value
    }
  }
}
