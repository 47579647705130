PageApp.Controllers.LoginRouter = function (config) {
  function redirectToOAuthLogout (logoutUrl) {
    window.location.replace(logoutUrl)
  }

  function redirectToLogout () {
    var contextPath = myApp.ent.config.get('contextPath')
    myApp.controllers.application.router.navigate(contextPath + '/home', { trigger: true })
  }

  function initEvents () {
    myApp.vent.on('login:display', function () {
      var isUserLoggedIn = myApp.ent.user.isLoggedIn()
      if (!isUserLoggedIn) {
        var view = new PageApp.Views.LoginView({ modal: true })
        return myApp.myModalRegion.show(new PageApp.Views.ModalLayout({ view: view }))
      }
    }, this)

    myApp.vent.on('modal:closed', function () {
      var referer = myApp.ent.config.getReferer()
      if (referer === myApp.ent.config.get('contextPath') + '/login') {
        referer = myApp.ent.config.get('contextPath') + '/home'
      }
      return myApp.router.navigate(referer, { trigger: true })
    }, this)

    myApp.vent.on('authentication:success', function (userModel, isSilent) {
      handleAuthenticationElementHooks(true)
      if (userModel) {
        myApp.ent.user.set(userModel)
        if (userModel.accessToken) {
          window.localStorage.setItem('bidJSToken', userModel.accessToken)
        }
        // if we have bidJS Modules on the page, we may need to force it to get the baseModels again
        if (window.bidjsHooks && window.bidjsHooks.forceBaseModelReload) {
          window.bidjsHooks.forceBaseModelReload()
        }
        var referer = myApp.ent.config.getReferer()
        if (referer === myApp.ent.config.get('contextPath') + '/login') {
          referer = myApp.ent.config.get('contextPath') + '/home'
        }
        myApp.myModalRegion.empty()
        return myApp.router.navigate(referer, { trigger: true })
      }
    }, this)

    myApp.vent.on('authentication:remove', function (isSilent) {
      handleAuthenticationElementHooks(false)
      window.localStorage.removeItem('bidJSToken')
      // if we have bidJS Modules on the page, we may need to force it to get the baseModels again
      if (window.bidjsHooks && window.bidjsHooks.forceBaseModelReload) {
        window.bidjsHooks.forceBaseModelReload()
      }
      myApp.refreshModels({ callback: _.bind(function (models) {
        if (!isSilent) {
          myApp.router.navigate(myApp.ent.config.getReferer(), { trigger: true })
        }
      }, this) })
      if (!isSilent) {
        var oAuthLogoutUrl = PageApp.ent.config.getOAuthLogoutEndpoint()
        if (oAuthLogoutUrl) {
          console.log('Redirecting to logout - ', oAuthLogoutUrl)
          redirectToOAuthLogout(oAuthLogoutUrl)
        } else {
          console.log('Logged out, sending to home')
          redirectToLogout()
        }
      }
    }, this)

    $(document).on('click', '.x-bidlogix--trigger-my-settings', function (e) {
      e.preventDefault()
      myApp.controllers.application.router.navigate(myApp.ent.config.get('contextPath') + '/mySettings', { trigger: true })
    })

    $(document).on('click', '.x-bidlogix--trigger-my-bids', function (e) {
      e.preventDefault()
      myApp.controllers.application.router.navigate(myApp.ent.config.get('contextPath') + '/myBids', { trigger: true })
    })

    $(document).on('click', '.x-bidlogix--trigger-login', function (e) {
      e.preventDefault()
      myApp.router.navigate('#!/login', { trigger: true })
    })

    $(document).on('click', '.x-bidlogix--trigger-logout', function (e) {
      e.preventDefault()
      myApp.router.navigate('#!/logout', { trigger: true, replace: true })
    })

    $(document).on('click', '.x-bidlogix--trigger-register', function (e) {
      e.preventDefault()
      myApp.controllers.application.router.navigate(myApp.ent.config.get('contextPath') + '/createAccount', { trigger: true })
    })
  }

  function handleAuthenticationElementHooks (isAuthenticated) {
    if (isAuthenticated) {
      var displayName = myApp.ent.user.get('displayName')
      if (displayName) {
        $(document).find('.x-bidlogix--templated-user').text(displayName)
      }
      var isAdminAvailable = myApp.ent.user.get('adminLoginPermitted')
      var isVendorAvailable = myApp.ent.user.get('publicVendorAdmin')
      if (isAdminAvailable) {
        var adminUrl = myApp.ent.config.getAdminUrl()
        if (adminUrl) {
          $(document)
            .find('.x-bidlogix--administrator-show')
            .removeClass('hidden')
            .children('a')
            .attr('href', adminUrl)
        }
      } else {
        $(document).find('.x-bidlogix--administrator-show').addClass('hidden')
      }
      if (isVendorAvailable) {
        $(document)
          .find('.x-bidlogix--vendor-show')
          .removeClass('hidden')
      } else {
        $(document).find('.x-bidlogix--vendor-show').addClass('hidden')
      }
      $(document).find('.x-bidlogix--authenticated-hide').addClass('hidden')
      $(document).find('.x-bidlogix--authenticated-show').removeClass('hidden')
    } else {
      $(document).find('.x-bidlogix--authenticated-show').addClass('hidden')
      $(document).find('.x-bidlogix--authenticated-hide').removeClass('hidden')
      $(document).find('.x-bidlogix--administrator-show').addClass('hidden')
      $(document).find('.x-bidlogix--vendor-show').addClass('hidden')
    }
  }

  return {
    initialize: function (models) {
      initEvents()
    }
  }
}
