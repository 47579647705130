PageApp.TypeUtils = function () {
  function toNumericCharsOnlyInternal (text, thousandsSeparator, allowDecimal) {
    var decimalSeparator = '.'
    if (!thousandsSeparator) {
      thousandsSeparator = ','
    }
    if (thousandsSeparator !== ',') {
      thousandsSeparator = '.'
      decimalSeparator = ','
    }
    var textStr = text.toString()
    var numberOfDecimalSeparators = textStr.split(decimalSeparator).length - 1
    if (numberOfDecimalSeparators > 1 || !allowDecimal) {
      // Get rid of all non numbers
      return textStr.replace(/[^\d]/g, '')
    } else {
      // Get rid of all non number excluding the decimalSeparators
      var re = new RegExp('[^\\d' + decimalSeparator + ']', 'g')

      textStr = textStr.replace(re, '')

      if (numberOfDecimalSeparators === 1) {
        // Ensure no more than 2 digits after the decimal point
        var poundsAndPence = textStr.split(decimalSeparator)
        var pounds = poundsAndPence[0]
        var pence = poundsAndPence[1]
        pence = pence.substring(0, 2)
        textStr = pounds + decimalSeparator + pence
      }
      // Works to format to 2 dp but causes issues when using backspace
      // var asFloat = parseFloat(textStr);
      // return asFloat.toFixed(2).toString();

      return textStr
    }
  }

  function toFormattedNumberInternal (text, thousandsSeparator, allowDecimal) {
    if (!thousandsSeparator) {
      thousandsSeparator = ','
    }
    try {
      if (allowDecimal) {
        return toNumericCharsOnlyInternal(text.toString(), thousandsSeparator, true).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + thousandsSeparator)
      } else {
        return toNumericCharsOnlyInternal(text.toString(), thousandsSeparator, false).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + thousandsSeparator)
      }
    } catch (err) {
      console.log(err)
      return ''
    }
  }

  return {
    arrayContains: function (theArray, theValue) {
      for (var key in theArray) {
        if (theArray[key] === theValue) {
          return true
        }
      }
      return false
    },
    toNumber: function (value) { // delete obj e.g. obj = {itemId: 3245}.
      if (typeof value === 'undefined' || (typeof value === 'string' && value.length === 0)) {
        throw new Error('value was not entered')
      }
      value = value.replace(/[^\d]/g, '')
      var newNumber = parseInt(value)
      if (typeof newNumber !== 'number' || isNaN(newNumber)) {
        throw new Error('value (' + value + ') is not a number')
      }
      return newNumber
    },
    toDecimalNumber: function (value) { // delete obj e.g. obj = {itemId: 3245}.
      if (typeof value === 'undefined' || (typeof value === 'string' && value.length === 0)) {
        throw new Error('value was not entered')
      }
      value = value.replace(/[^0-9.]/g, '')
      var newNumber = Number(value)
      if (isNaN(newNumber)) {
        throw new Error('value (' + value + ') is not a number')
      }
      return newNumber
    },
    toFloat: function (value) { // delete obj e.g. obj = {itemId: 3245}.
      if (typeof value === 'undefined' || (typeof value === 'string' && value.length === 0)) {
        throw new Error('value was not entered')
      }
      var newNumber = parseFloat(value)
      if (typeof newNumber !== 'number') {
        throw new Error('value (' + value + ') is not a number')
      }
      return newNumber
    },

    toNumericCharsOnly: function (text, thousandsSeparator) {
      return toNumericCharsOnlyInternal(text, thousandsSeparator, true)
    },

    toNumericCharsOnlyNoDecimal: function (text, thousandsSeparator) {
      return toNumericCharsOnlyInternal(text, thousandsSeparator, false)
    },

    toFormattedNumber: function (text, thousandsSeparator) {
      return toFormattedNumberInternal(text, thousandsSeparator, true)
    },

    // Allow numbers only up to 13 digits i.e. 1 trillion
    toFormattedNumberLimited: function (text, thousandsSeparator) {
      var limitedText = toNumericCharsOnlyInternal(text, thousandsSeparator, true).substr(0, 13)
      return toFormattedNumberInternal(limitedText, thousandsSeparator, true)
    },

    toFormattedNumberNoDecimal: function (text, thousandsSeparator) {
      return toFormattedNumberInternal(text, thousandsSeparator, false)
    },

    toFormattedNumberNoDecimalLimited: function (text, thousandsSeparator) {
      var limitedText = toNumericCharsOnlyInternal(text, thousandsSeparator, false).substr(0, 13)
      return toFormattedNumberInternal(limitedText, thousandsSeparator, false)
    },

    isWholeNumber: function (text, thousandsSeparator) {
      var decimalSeparator = '.'
      if (!thousandsSeparator) {
        thousandsSeparator = ','
      }
      if (thousandsSeparator !== ',') {
        thousandsSeparator = '.'
        decimalSeparator = ','
      }
      try {
        return this.toNumericCharsOnly(text.toString(), thousandsSeparator).indexOf(decimalSeparator) === -1
      } catch (err) {
        console.log(err)
        return false
      }
    },

    urlQueryParams: function () {
      var vars = []
      var hash
      var q = document.URL.split('?')[1]
      if (q !== undefined) {
        q = q.split('&')
        for (var i = 0; i < q.length; i++) {
          hash = q[i].split('=')
          vars.push(hash[1])
          vars[hash[0]] = hash[1]
        }
      }
      return vars
    },

    urlQueryParam: function (param) {
      var hash
      var q = document.URL.split('?')[1]
      if (q !== undefined && q.indexOf(param) > -1) {
        q = q.split('&')
        for (var i = 0; i < q.length; i++) {
          hash = q[i].split('=')
          if (hash[0] === param) {
            return hash[1]
          }
        }
      }
    },

    toCloudinaryImageSrc: function (image, width, height, quality, operation, additions) {
      if (!image || !image.cloudinaryBasePath || !image.cloudinaryVersionAndPublicId) {
        return
      }

      var hasSpec = (width || height || quality || operation || additions)

      var src = image.cloudinaryBasePath
      if (quality) src += 'q_' + quality + ','
      if (width) src += 'w_' + width + ','
      if (height) src += 'h_' + height + ','
      if (operation) src += 'c_' + operation + ','
      if (additions) src += additions + ','
      if (hasSpec) src = src.slice(0, -1) + '/'
      src += image.cloudinaryVersionAndPublicId

      return src
    },

    abbreviate: function (text, maxLength) {
      if (!text || !maxLength || text.length <= maxLength) {
        return text
      } else {
        return text.substring(0, maxLength - 1) + '&hellip;'
      }
    },

    capitalise: function (text) {
      if (text && text.length > 0) {
        return text.charAt(0).toUpperCase() + text.slice(1)
      } else {
        return ''
      }
    },

    datepickerOptions: function () {
      return {
        dateFormat: 'yy-mm-dd',
        placeHolderDateFormat: 'yyyy-mm-dd', // The placeholder should show the full 4 digit year - that's what 'yy' in dateFormat becomes
        duration: 'fast',
        // For i18n
        closeText: myApp.reqres.request('i16:getString', 'JSTextCodes_CLOSE'),
        currentText: myApp.reqres.request('i16:getString', 'JSTextCodes_TODAY'),
        nextText: myApp.reqres.request('i16:getString', 'JSTextCodes_NEXT'),
        prevText: myApp.reqres.request('i16:getString', 'JSTextCodes_PREV'),
        weekHeader: myApp.reqres.request('i16:getString', 'JSTextCodes_WEEKHEADER'),
        dayNames: [
          myApp.reqres.request('i16:getString', 'JSTextCodes_SUNDAY'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_MONDAY'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_TUESDAY'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_WEDNESDAY'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_THURSDAY'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_FRIDAY'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_SATURDAY')
        ],
        dayNamesMin: [
          myApp.reqres.request('i16:getString', 'JSTextCodes_SU'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_MO'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_TU'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_WE'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_TH'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_FR'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_SA')
        ],
        dayNamesShort: [
          myApp.reqres.request('i16:getString', 'JSTextCodes_SUN'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_MON'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_TUE'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_WED'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_THU'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_FRI'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_SAT')
        ],
        monthNames: [
          myApp.reqres.request('i16:getString', 'JSTextCodes_JANUARY'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_FEBRUARY'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_MARCH'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_APRIL'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_MAY'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_JUNE'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_JULY'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_AUGUST'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_SEPTEMBER'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_OCTOBER'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_NOVEMBER'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_DECEMBER')
        ],
        JspPublicCodes_JSP_MY_SALES_SHOW_ITEMS_FROM: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SALES_SHOW_ITEMS_FROM'),
        monthNamesShort: [
          myApp.reqres.request('i16:getString', 'JSTextCodes_JAN'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_FEB'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_MAR'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_APR'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_MAY'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_JUN'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_JUL'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_AUG'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_SEP'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_OCT'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_NOV'),
          myApp.reqres.request('i16:getString', 'JSTextCodes_DEC')
        ]
      }
    }
  }
}
