PageApp.Views.ItemRelationsView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.itemRelations,
  serializeData: function () {
    var dims = { width: 170, height: 125 }
    dims.operation = myApp.ent.config.get('itemImageTransformOperation')

    var content = {
      dims: dims,
      awaitingImageMessage: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AWAITING_IMAGE'),
      JspPublicCodes_JSP_RELATED_ITEMS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_RELATED_ITEMS')
    }

    // unescape and trim the title for tooltips.
    var relatedItems = this.model.attributes.relatedItems
    var escapedText = document.createElement('textarea')
    for (var i = 0; i < relatedItems.length; i++) {
      if (relatedItems[i].title.length > 100) {
        escapedText.innerHTML = relatedItems[i].title.slice(0, 100) + '...'
      } else {
        escapedText.innerHTML = relatedItems[i].title
      }
      this.model.attributes.relatedItems[i].tooltip = escapedText.value
    }
    _.extend(content, this.model.attributes)
    return content
  }
})
