/**
 * NO_BIDS_OPEN(0), NO_BIDS_CLOSED(1), WINNING(2), LOSING(3), WON(4), LOST(5), LOSING_ROOM(6), LOST_ROOM(7), LOST_CANCELLED(8),LOSING_CANCELLED(9);
 */
PageApp.Ent.SellingItem = Backbone.Model.extend({
  idAttribute: 'itemId',

  initialize: function () {
  },
  ringDetails: function () {
    return this.get('ringDetails')
  },
  isInPlay: function () {
    return (typeof this.get('ringDetails') !== 'undefined' && this.get('ringDetails') !== null)
  },
  isWon: function (userBiddingStatus) {
    return this.get('userBiddingData').userBiddingStatus === myApp.ent.status.attributes.userBiddingStatuses.WON
  },
  isWinningOrWon: function () {
    var userBiddingStatus = this.get('userBiddingData').userBiddingStatus
    return userBiddingStatus === myApp.ent.status.attributes.userBiddingStatuses.WINNING ||
      userBiddingStatus === myApp.ent.status.attributes.userBiddingStatuses.WON
  },
  isLosingOrLost: function () {
    var userBiddingStatus = this.get('userBiddingData').userBiddingStatus
    return userBiddingStatus === myApp.ent.status.attributes.userBiddingStatuses.LOSING ||
      userBiddingStatus === myApp.ent.status.attributes.userBiddingStatuses.LOST ||
      userBiddingStatus === myApp.ent.status.attributes.userBiddingStatuses.LOSING_ROOM ||
      userBiddingStatus === myApp.ent.status.attributes.userBiddingStatuses.LOSING_CANCELLED ||
      userBiddingStatus === myApp.ent.status.attributes.userBiddingStatuses.LOST_CANCELLED ||
      userBiddingStatus === myApp.ent.status.attributes.userBiddingStatuses.LOST_ROOM
  },
  isInactive: function () {
    var userBiddingStatus = this.get('userBiddingData').userBiddingStatus
    return (!this.isWinningOrWon(userBiddingStatus))
  },
  hasBid: function (userId) {
    try {
      for (var key in this.get('ringDetails').bidHistory) {
        var bid = this.get('ringDetails').bidHistory[key]
        if (bid.userId === userId) {
          return bid
        }
      }
    } catch (e) {
      // if this is not the inPlay item.
    }
  },
  getItemId: function () {
    var itemId = this.get('itemId')
    if (!itemId) {
      return -1
    }
    return itemId
  },
  hasMaxBid: function (userId) {
    try {
      for (var key in this.attributes.maxBidHistory) {
        var bid = this.attributes.maxBidHistory[key]
        if (bid.userId === userId) {
          return bid
        }
      }
    } catch (e) {
      // if this is not the inPlay item.
    }
  },
  getCurrentBidAmount: function (defAmount) {
    try {
      return this.get('ringDetails').currentBid.amount
    } catch (e) {
      return defAmount
    }
  },
  getNextBidAmount: function (defAmount) {
    try {
      return this.get('ringDetails').nextBid.amount
    } catch (e) {
      return defAmount
    }
  },
  getAskingPrice: function (defAmount) {
    try {
      return this.get('ringDetails').askingPrice
    } catch (e) {
      return defAmount
    }
  },
  getCurrencySymbol: function () {
    var highBidF = myApp.ent.inPlay.get('amountHighestBidFormatted')
    if (highBidF) {
      var index = highBidF.indexOf(';')
      return highBidF.substring(0, index + 1)
    }
    return ''
  },
  getBidCount: function () {
    try {
      return this.attributes.ringDetails.bidHistory.length
    } catch (e) {
      return 0
    }
  },
  getBidCountMessage: function () {
    var bidCount = 0
    try {
      bidCount = this.attributes.ringDetails.bidHistory.length
    } catch (e) {
      bidCount = 0
    }
    if (bidCount === 1) {
      return bidCount + ' ' + myApp.reqres.request('i16:getString', 'WebcastCodes_WC_BID_TEXT')
    } else {
      return bidCount + ' ' + myApp.reqres.request('i16:getString', 'WebcastCodes_WC_BIDS_TEXT')
    }
  },
  getCurrentState: function () {
    var itemStatusId = this.get('itemStatusId')
    if (itemStatusId === 4 || itemStatusId === 13) {
      return 'live'
    } else if (itemStatusId === 5 || itemStatusId === 14) {
      return 'withdrawn'
    } else if (itemStatusId === 6 || itemStatusId === 7 || itemStatusId === 10 || itemStatusId === 11 || itemStatusId === 12) {
      return 'sold'
    } else if (itemStatusId === 8) {
      return 'unsold'
    } else if (itemStatusId === 9 || itemStatusId === 1 || itemStatusId === 2 || itemStatusId === 3) {
      return 'suspended'
    }
  },
  getUserBiddingData: function (pendingItem) {
    try {
      if (!pendingItem) {
        return
      }
      var ubd1 = {}
      var loggedIn = myApp.ent.user.isLoggedIn()
      var userStatus = this.getUserStatus(pendingItem, loggedIn)
      var statusMessage = this.getUserStatusMessage(userStatus, pendingItem, loggedIn)
      var onceTwiceMessage = this.getOnceTwiceMessage(pendingItem)
      if (onceTwiceMessage) {
        statusMessage = onceTwiceMessage
      }
      ubd1.statusMessage = statusMessage
      ubd1.wonLostMessage = this.getWonLostMessage(userStatus, pendingItem, loggedIn)
      ubd1.userBiddingStatus = userStatus
      this.setAutoBidInfo(ubd1, pendingItem)
      return ubd1
    } catch (e) {
      throw e
    }
  },
  setAutoBidInfo: function (ubd1, pendingItem) {
    if (!pendingItem) {
      return
    }
    ubd1.maxBidAmount = undefined
    ubd1.maxBidAmountFormatted = undefined

    if (pendingItem.userBiddingData) {
      ubd1.maxBidAmount = pendingItem.userBiddingData.maxBidAmount
      ubd1.maxBidAmountFormatted = pendingItem.userBiddingData.maxBidAmountFormatted
    } else if (pendingItem.maxBidHistory) {
      for (var key in pendingItem.maxBidHistory) {
        var maxBid = pendingItem.maxBidHistory[key]
        if (myApp.ent.user.get('id') === maxBid.userId) {
          ubd1.maxBidAmount = maxBid.amount
          ubd1.maxBidAmountFormatted = maxBid.amountFormatted
        }
      }
    }
  },
  getUserStatus: function (pendingItem, loggedIn) {
    if (!pendingItem || !pendingItem.ringDetails) {
      return 0
    }
    var userBiddingStatuses = myApp.ent.status.attributes.userBiddingStatuses
    var status = userBiddingStatuses.NO_BIDS_OPEN
    var sessionUserId = myApp.ent.user.attributes.id
    var highestBid = pendingItem.ringDetails.currentBid
    var userHasBid = false
    var bid
    for (var key in pendingItem.ringDetails.bidHistory) {
      bid = pendingItem.ringDetails.bidHistory[key]
      if (bid.userId === sessionUserId && bid.status === 1) {
        userHasBid = bid
        break
      }
    }
    var userCancelledBid = false
    for (var key1 in pendingItem.ringDetails.bidHistory) {
      bid = pendingItem.ringDetails.bidHistory[key1]
      if (bid.userId === sessionUserId && bid.status > 1) {
        userCancelledBid = bid
        break
      }
    }
    if (!pendingItem.live) {
      if (loggedIn) {
        if (highestBid) {
          if (highestBid.userId === sessionUserId) {
            status = userBiddingStatuses.WON
          } else if (userHasBid) {
            status = userBiddingStatuses.LOST
            if (highestBid.reserve) {
              status = userBiddingStatuses.LOST_ROOM
            }
          } else {
            status = userBiddingStatuses.NO_BIDS_CLOSED
          }
        } else {
          status = userBiddingStatuses.NO_BIDS_CLOSED
        }
      } else {
        status = userBiddingStatuses.NO_BIDS_CLOSED
      }
    } else {
      if (loggedIn) {
        if (highestBid && highestBid.userId === sessionUserId) {
          status = userBiddingStatuses.WINNING
        } else {
          if (userHasBid) {
            status = userBiddingStatuses.LOSING
            if (highestBid.reserve) {
              status = userBiddingStatuses.LOSING_ROOM
            }
          }
        }
      }
    }
    // check for cancelled bids if it appears the user has no bids...
    if (loggedIn) {
      if (status === userBiddingStatuses.NO_BIDS_CLOSED || status === userBiddingStatuses.NO_BIDS_OPEN) {
        if (userCancelledBid) {
          if (status === userBiddingStatuses.NO_BIDS_CLOSED) {
            status = userBiddingStatuses.LOST_CANCELLED
            if (highestBid.reserve) {
              status = userBiddingStatuses.LOST_ROOM
            }
          } else if (status === userBiddingStatuses.NO_BIDS_OPEN) {
            status = userBiddingStatuses.LOSING_CANCELLED
          }
        }
      }
    }
    return status
  },
  getOnceTwiceMessage: function (pendingItem) {
    var onceTwiceStatus = myApp.ent.auction.attributes.onceTwiceStatus
    var webcastStateId = myApp.ent.auction.attributes.webcastStateId
    var onceTwiceStatuses = myApp.ent.status.attributes.onceTwiceStatusesByName
    var onceTwiceMessage
    if (pendingItem) {
      // in webcast2 this message overrides the status message - therefore we to take care when to override the status message
      if (myApp.ent.status.w2StateInPlayBiddable(webcastStateId)) {
        if (onceTwiceStatus === onceTwiceStatuses.ONCE) {
          onceTwiceMessage = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_RNDM_GOING_ONCE')
        } else if (onceTwiceStatus === onceTwiceStatuses.TWICE) {
          onceTwiceMessage = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_RNDM_GOING_TWICE')
        } else if (onceTwiceStatus === onceTwiceStatuses.LAST) {
          onceTwiceMessage = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_RNDM_LAST_CHANCE')
        }
      } else if (myApp.ent.status.w2StateInPlayPaused(webcastStateId)) {
        onceTwiceMessage = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_LIVE_BIDDING_PAUSED')
      }
    }
    return onceTwiceMessage
  },
  getUserStatusMessage: function (userStatus, pendingItem, loggedIn) {
    var webcastStateId = myApp.ent.auction.attributes.webcastStateId
    var webcastStatuses = myApp.ent.status.attributes.webcastStatesByName
    var userBiddingStatuses = myApp.ent.status.attributes.userBiddingStatuses

    var statusMessageCode
    if (!loggedIn) {
      statusMessageCode = 'WebcastCodes_WC_ACTMSG_YOU_NOT_LOGGED'
    } else if (userStatus === userBiddingStatuses.NO_BIDS_CLOSED) {
      statusMessageCode = 'WebcastCodes_WC_ACTMSG_CLOSED'
    } else if (userStatus === userBiddingStatuses.LOST || userStatus === userBiddingStatuses.LOST_ROOM || userStatus === userBiddingStatuses.WON) {
      if (userStatus === userBiddingStatuses.LOST) {
        statusMessageCode = 'WebcastCodes_WC_ACTMSG_YOU_WAS_NOT_HIGHTEST'
      } else if (userStatus === userBiddingStatuses.LOST_ROOM) {
        statusMessageCode = 'WebcastCodes_WC_ACTMSG_ROOM_WAS_HIGHEST'
      } else if (userStatus === userBiddingStatuses.LOST_CANCELLED) {
        statusMessageCode = 'WebcastCodes_WC_ACTMSG_YOU_WAS_NOT_HIGHTEST'
      } else if (userStatus === userBiddingStatuses.WON) {
        if (pendingItem.group && myApp.ent.status.w2StateInPlayEndedWaitingChoice(webcastStateId)) {
          if (pendingItem.choiceEnumId === 2) { // myApp.ent.status.attributes.choicesByName['Full']) {
            statusMessageCode = 'WebcastCodes_WC_BUTTON_SELECT_CHOICE'
          } else if (pendingItem.choiceEnumId === 3) { // myApp.ent.status.attributes.choicesByName['Numerical Order']) {
            statusMessageCode = 'WebcastCodes_WC_GROUPING_ENTER_QUANTITY'
          }
        } else {
          statusMessageCode = 'WebcastCodes_WC_ACTMSG_YOU_WAS_HIGHTEST'
        }
      }
    } else {
      if (myApp.ent.userRegistration && myApp.ent.userRegistration.attributes.approved) {
        if (!pendingItem.ringDetails ||
          webcastStateId === webcastStatuses.COUNTING_DOWN ||
          webcastStateId === webcastStatuses.DEFINED ||
          webcastStateId === webcastStatuses.STARTED ||
          webcastStateId === webcastStatuses.FINISHED) {
          statusMessageCode = 'WebcastCodes_WC_LIVE_BIDDING_COMMENCES_SHORTLY'
        } else if (myApp.ent.status.w2StateInPlayPaused(webcastStateId)) {
          statusMessageCode = 'WebcastCodes_WC_LIVE_BIDDING_PAUSED'
        } else if (myApp.ent.status.w2StateInPlayEndedAny(webcastStateId)) {
          statusMessageCode = 'WebcastCodes_WC_ACTMSG_YOU_WAS_NOT_HIGHTEST'
          if (userStatus === userBiddingStatuses.WINNING) {
            statusMessageCode = 'WebcastCodes_WC_ACTMSG_YOU_IS_HIGHTEST'
          }
        } else {
          statusMessageCode = myApp.ent.userRegistration.attributes.statusLabel
          if (myApp.ent.userRegistration) {
            statusMessageCode = myApp.ent.userRegistration.attributes.statusLabel
          }
          if (userStatus === userBiddingStatuses.WINNING) {
            statusMessageCode = 'WebcastCodes_WC_ACTMSG_YOU_IS_HIGHTEST'
          } else if (userStatus === userBiddingStatuses.LOSING) {
            statusMessageCode = 'WebcastCodes_WC_ACTMSG_ONLINE_IS_HIGHEST'
          } else if (userStatus === userBiddingStatuses.LOSING_CANCELLED) {
            statusMessageCode = 'WebcastCodes_WC_ACTMSG_ONLINE_IS_HIGHEST_CANCELLED'
          } else if (userStatus === userBiddingStatuses.LOSING_ROOM) {
            statusMessageCode = 'WebcastCodes_WC_ACTMSG_ROOM_IS_HIGHEST'
          } else if (userStatus === userBiddingStatuses.NO_BIDS_OPEN) {
            statusMessageCode = 'WebcastCodes_WC_ACTMSG_NO_BIDS_OPEN'
          }
        }
      } else {
        if (!myApp.ent.userRegistration || !myApp.ent.userRegistration.attributes.registered) {
          statusMessageCode = 'AuctionRegistrantStatus_NOT_REGISTERED' // myApp.ent.userRegistration.attributes.statusLabel;
        } else {
          statusMessageCode = myApp.ent.userRegistration.attributes.statusLabel
        }
      }
    }
    return myApp.reqres.request('i16:getString', statusMessageCode)
  },
  getWonLostMessage: function (userStatus, pendingItem, loggedIn) {
    var messageCode
    var userBiddingStatuses = myApp.ent.status.attributes.userBiddingStatuses
    if (pendingItem.live || !loggedIn || userStatus === userBiddingStatuses.NO_BIDS_CLOSED) {
      // messageCode = null;
    } else if (userStatus === userBiddingStatuses.LOST || userStatus === userBiddingStatuses.LOST_ROOM) {
      if (pendingItem.group) {
        messageCode = 'WebcastCodes_WC_DID_NOT_WIN_GROUP'
      } else {
        messageCode = 'WebcastCodes_WC_DID_NOT_WIN_ITEM'
      }
    } else if (userStatus === userBiddingStatuses.WON) {
      if (pendingItem.group) {
        messageCode = 'WebcastCodes_WC_DID_WIN_GROUP'
      } else {
        messageCode = 'WebcastCodes_WC_DID_WIN_ITEM'
      }
    }
    if (!messageCode) {
      return ''
    } else {
      return myApp.reqres.request('i16:getString', messageCode)
    }
  }
})
