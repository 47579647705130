PageApp.Views.LoginView = Backbone.Marionette.LayoutView.extend({
  template: hbs.templates.login,
  events: {
    'click .x-login--cancel': 'handleLoginCancel',
    'submit .x-login--form': 'handleLoginSubmit'
  },
  serializeData: function () {
    return {
      contextPath: myApp.ent.config.get('contextPath'),
      error: this.error,
      translations: {
        ctaLoginSubmit: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LOGIN_LOGIN'),
        ctaForgottenCredentials: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LOGIN_FORGOTTEN'),
        ctaCreateAccount: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CREATE_ACCOUNT'),
        labelUsername: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LOGIN_USERNAME'),
        labelPassword: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LOGIN_PASSWORD'),
        contentLoginDescription: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LOGIN_DESCRIPTION'),
        titleLogin: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LOGIN_TO_ACCOUNT')
      }
    }
  },
  handleLoginCancel: function (e) {
    myApp.vent.trigger('modal:closed')
  },
  handleLoginSubmit: function (e) {
    e.preventDefault()
    this.error = null
    $('#loginSubmit').attr('disabled', true)
    var username = $('#loginUsername').val()
    var password = $('#loginPassword').val()
    this.handleLoginRequest(username, password)
  },
  handleLoginRequest: function (username, password) {
    return myApp.utils.ajax.postDirect({
      url: myApp.actions.login(),
      data: {
        '@class': 'com.bidlogix.scaffold.models.user.LoginModel',
        username: username,
        password: password
      },
      method: 'POST',
      successHandler: this.handleLoginResponse.bind(this)
    })
  },
  handleLoginResponse: function (res) {
    $('#loginSubmit').attr('disabled', false)
    return (res.failed || !res.accessToken) ? this.handleLoginFailure(res) : this.handleLoginSuccess(res)
  },
  handleLoginSuccess: function (user) {
    if (user && user.accessToken) {
      myApp.ent.user.set(user)
      return myApp.vent.trigger('authentication:success', user, false)
    }
    console.error('unable to parse token from response', user)
    this.error = { text: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_UNEXPECTED_ERROR'), level: 'danger' }
    this.render()
  },
  handleLoginFailure: function (res) {
    this.error = { text: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LOGIN_CREDENTIALS_ERROR'), level: 'danger' }
    this.render()
  }
})
