PageApp.MiscUtils = function () {
  return {
    sendGoogleAnalytics: function (eventCategory, eventAction, eventLabel, page) {
      const gaName = window.GoogleAnalyticsObject
      try {
        if (page) {
          window[gaName]('set', 'page', page)
          window[gaName]('set', 'hostname', window.location.hostname)
          window[gaName]('send', 'pageview')
          if (myApp.ent.webApp.attributes.analyticsAllowed) {
            try {
              window[gaName]('app.set', 'page', page)
              window[gaName]('app.set', 'hostname', window.location.hostname)
              window[gaName]('app.send', 'pageview')
            } catch (e) {
              console.log('An error occurred while sending analytics.')
            }
          }
        }
        if (eventCategory) {
          window[gaName]('send', {
            hitType: 'event',
            eventCategory: eventCategory,
            eventAction: eventAction,
            eventLabel: eventLabel
          })
          if (myApp.ent.webApp.attributes.analyticsAllowed) {
            try {
              window[gaName]('app.send', {
                hitType: 'event',
                eventCategory: eventCategory,
                eventAction: eventAction,
                eventLabel: eventLabel
              })
            } catch (e) {
              console.log('An error occurred while sending analytics.')
            }
          }
        }
      } catch (err) {
        if (console) console.log('Unable to send analytics event.')
      }
    },
    resetScrollPosition: function () {
      document.getElementById('bidlogix-app').scrollIntoView(true)
    }
  }
}
